import { ROLE_KIND, ROLE_PARTNER, ROLE_VERZEKERINGNEMER } from '@/constants';
import { ApiClient } from '@/utils/apiClient';
import { formatDatumString } from '@/utils/formatters';

const createStore = app => {
    const state = {
        prefilldata: null,
        offertedata: null,
        offertedataInvalid: false,
        collectiviteitnummer: null,
        collectiviteitinformatie: null,
        collectiviteitVoordelen: null,
        canRedirect: false,
        collectiviteitPending: false
    };

    const getters = {
        getPrefillData: state => state.prefilldata,
        getOfferteData: state => state.offertedata,
        getCollectiviteitnummer: state => state.collectiviteitnummer,
        getCollectiviteitinformatie: state => state.collectiviteitinformatie,
        canRedirect: state => state.canRedirect,
        getCollectiviteitPending: state => state.collectiviteitPending,
        getOfferteDataInvalid: state => state.offertedataInvalid
    };

    const actions = {
        clear({ commit }) {
            commit('CLEAR');
        },
        clearCollectiviteit({ commit }) {
            commit('CLEAR_COLLECTIVITEIT');
        },
        async fetchProductenBijQuerystring({ commit }, requestObject) {
            try {
                const { data } = await ApiClient(this._vm?.$jss.appContext()).post(
                    '/clientcontroller/prefillclient/ophalenproductenbijquerystring',
                    requestObject
                );
                commit('SET_PREFILLDATA', data);
            } catch (error) {
                console.log('Kan geen producten ophalen:', error.message);
            }
        },
        canRedirect: ({ commit }, value) => {
            commit('CAN_REDIRECT', value);
        },
        async fetchPrefillData({ dispatch, commit }, affiliateKey) {
            try {
                const appContext = app.config.globalProperties.$jss.appContext();
                const { data } = await ApiClient(appContext).get(
                    `/clientcontroller/prefillclient/prefilldata?prefillDataKey=${affiliateKey}`
                );

                if (data) {
                    commit('SET_PREFILLDATA', data);

                    const verzekerden = Object.keys(data).filter(
                        key => key.includes('GeboorteDatum') && data[key] !== null
                    );

                    verzekerden.forEach(function (key, index) {
                        const geboortedatum = data[key].split('T')[0];
                        const userIndex = index + 1;

                        dispatch(
                            'users/setGeboortedatum',
                            { index: userIndex, value: geboortedatum },
                            { root: true }
                        );

                        switch (index) {
                            case 0:
                                dispatch(
                                    'users/setRol',
                                    { index: userIndex, value: ROLE_VERZEKERINGNEMER },
                                    { root: true }
                                );
                                break;
                            case 1:
                                dispatch(
                                    'users/setRol',
                                    { index: userIndex, value: ROLE_PARTNER },
                                    { root: true }
                                );
                                break;
                            default:
                                dispatch(
                                    'users/setRol',
                                    { index: userIndex, value: ROLE_KIND },
                                    { root: true }
                                );
                                break;
                        }
                    });
                }
            } catch (error) {
                console.log('Kan geen prefill data ophalen:', error.message);
            }
        },
        async fetchOfferteData({ dispatch, commit }, offerteId) {
            if (!offerteId) return;

            dispatch('setErrorStatus', false, { root: true });
            dispatch('setPendingProductenStatus', true, { root: true });
            commit('SET_COLLECTIVITEIT_PENDING', true);

            try {
                const appContext = app.config.globalProperties.$jss.appContext();
                const { data } = await ApiClient(appContext).post(
                    `/clientcontroller/salesfunnelclient/ophalenoffertemail?mailLink=${offerteId}`
                );

                if (data && data.personen && data.personen.length) {
                    const { personen } = data;
                    let { collectiviteitsnummer } = data;
                    if (collectiviteitsnummer === 0 || collectiviteitsnummer === '0')
                        collectiviteitsnummer = null;
                    const verzekeringnemer = personen.find(({ rol }) => rol === 'V');
                    const partner = personen.find(({ rol }) => rol === 'P');
                    const kinderen = personen.filter(({ rol }) => rol === 'K');
                    const offerteData = { ...data, personen: {} };
                    const verzekerden = {};
                    const rollen = {
                        V: ROLE_VERZEKERINGNEMER,
                        P: ROLE_PARTNER,
                        K: ROLE_KIND
                    };
                    const setVerzekerdeData = (verzekerde, index) => {
                        const { geboortedatum, rol } = verzekerde;
                        offerteData.personen[index] = verzekerde;
                        verzekerden[index] = {
                            geboortedatum: formatDatumString(new Date(geboortedatum)),
                            rol: rollen[rol]
                        };
                    };

                    // Verzekeringnemer moet altijd een index van `1` hebben
                    // Partner moet altijd een index  van `2` hebben
                    // Kinderen moeten altijd een index van `3` of hoger hebben
                    if (verzekeringnemer) setVerzekerdeData(verzekeringnemer, 1);
                    if (partner) setVerzekerdeData(partner, 2);
                    kinderen.forEach((kind, i) => setVerzekerdeData(kind, 3 + i));

                    // Clear alle data (pakketten, users en collectiviteit)
                    dispatch('pakketten/clear', null, { root: true });
                    dispatch('users/clearUsers', null, { root: true });
                    dispatch('users/clearPakketkeuzes', null, { root: true });
                    dispatch('clearCollectiviteit');

                    // Sla offerte data, collectiviteit en verzekerden op
                    commit('SET_OFFERTE_DATA', offerteData);
                    commit('SET_COLLECTIVITEIT_NUMMER', collectiviteitsnummer);
                    dispatch('setCollectiviteitnummer', collectiviteitsnummer);
                    dispatch('users/setVerzekerden', verzekerden, { root: true });
                } else {
                    dispatch('setOfferteDataInvalid', true);
                }
            } catch (error) {
                console.log('Kan geen offerte data ophalen:', error.message);
                dispatch('setErrorStatus', true, { root: true });
            } finally {
                dispatch('setPendingProductenStatus', false, { root: true });
                commit('SET_COLLECTIVITEIT_PENDING', false);
            }
        },
        setOfferteDataInvalid: ({ commit }, invalid) => {
            commit('SET_OFFERTE_DATA_INVALID', invalid);
        },
        async fetchCollectiviteit({ dispatch, commit }, collectiviteitnummer) {
            commit('SET_COLLECTIVITEIT_PENDING', true);

            try {
                const appContext = app.config.globalProperties.$jss.appContext();
                const { data } = await ApiClient(appContext).post(
                    `/clientcontroller/salesfunnelclient/collectiviteit`,
                    {
                        Collectiviteitnummer: collectiviteitnummer,
                        Propositie: appContext.propositie
                    }
                );

                commit('SET_COLLECTIVITEIT_INFORMATIE', data);
            } catch (error) {
                dispatch('setCollectiviteitnummer', null);
            } finally {
                commit('SET_COLLECTIVITEIT_PENDING', false);
            }
        },
        setCollectiviteitnummer: ({ commit, state, dispatch }, value) => {
            if (value !== state.collectiviteitnummer) {
                commit('SET_COLLECTIVITEIT_NUMMER', value);
                dispatch('pakketten/clear', null, { root: true });
                dispatch('users/clearPakketkeuzes', null, { root: true });
                dispatch('users/setCollectiviteitNr', value, { root: true });

                if (value === null || value === '0') {
                    commit('SET_COLLECTIVITEIT_INFORMATIE', null);
                } else {
                    dispatch('fetchCollectiviteit', value);
                }

                dispatch('pakketten/ophalen', null, { root: true });
            } else if (!state.collectiviteitinformatie) {
                // For when we enter with a cookie but have no session storage
                if (value !== null && value !== '0') {
                    dispatch('fetchCollectiviteit', value);
                }
            }
        }
    };

    const mutations = {
        SET_PREFILLDATA: (state, data) => {
            state.prefilldata = data;
        },
        SET_OFFERTE_DATA: (state, data) => {
            state.offertedata = data;
        },
        SET_OFFERTE_DATA_INVALID: (state, data) => {
            state.offertedataInvalid = data;
        },
        SET_COLLECTIVITEIT_NUMMER: (state, collectiviteitnummer) => {
            state.collectiviteitnummer = collectiviteitnummer;
        },
        SET_COLLECTIVITEIT_INFORMATIE: (state, collectiviteitinformatie) => {
            state.collectiviteitinformatie = collectiviteitinformatie;
        },
        CAN_REDIRECT(state, data) {
            state.canRedirect = data;
        },
        CLEAR(state) {
            state.prefilldata = null;
            state.offertedata = null;
            state.offertedataInvalid = false;
        },
        CLEAR_COLLECTIVITEIT(state) {
            state.collectiviteitnummer = null;
            state.collectiviteitinformatie = null;
        },
        SET_COLLECTIVITEIT_PENDING(state, data) {
            state.collectiviteitPending = data;
        }
    };

    return {
        namespaced: true,
        state,
        getters,
        actions,
        mutations
    };
};

export const prefillStoragePaths = ['prefill'];

export default app => createStore(app);
