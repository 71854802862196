import { ApiClient } from '@/utils/apiClient';

const createStore = app => {
    const state = {
        vragen: null,
        adviezen: null,
        advies: '',
        pending: false,
        error: false
    };

    const getters = {
        getTvKeuzehulpVragen: state => state.vragen,
        getTvKeuzehulpAdviezen: state => state.adviezen,
        getAdviesContent: (state, getters) => {
            return getters.getTvKeuzehulpAdviezen.find(el => el.pakket === getters.getAdvies);
        },
        getAdvies: state => state.advies,
        getPending: state => state.pending,
        getError: state => state.error
    };

    const actions = {
        async getVragen({ getters, dispatch, commit }) {
            commit('SET_ERROR', false);

            // check of er bestaande vragen zijn in de store
            let result = getters.getTvKeuzehulpVragen;
            if (result) return result;

            // opnieuw ophalen
            result = await dispatch('ophalen');
            return result;
        },

        /**
         * Ophalen van TV Keuzehulp vragen van de server.
         *
         */
        async ophalen({ commit }) {
            commit('SET_ERROR', false);
            commit('SET_PENDING', true);

            try {
                const appContext = app.config.globalProperties.$jss.appContext();
                const propositie = appContext?.propositie;

                const { data } = await ApiClient(appContext).post(
                    `/clientcontroller/salesfunnelclient/ophalentvkeuzehulpcontent`,
                    {
                        Propositie: propositie
                    }
                );
                if (data?.isSucces === false) {
                    commit('SET_ERROR', true);
                }

                if (data?.tvKeuzehulpResponse?.vragen?.length > 0) {
                    commit('SET_TV_KEUZEHULP_VRAGEN', data?.tvKeuzehulpResponse?.vragen);
                }
                if (data?.tvKeuzehulpResponse?.adviezen?.length > 0) {
                    commit('SET_TV_KEUZEHULP_ADVIEZEN', data?.tvKeuzehulpResponse?.adviezen);
                }
            } catch (error) {
                commit('SET_ERROR', true);
            } finally {
                commit('SET_PENDING', false);
            }
        },

        setAdvies({ commit }, advies) {
            commit('SET_ADVIES', advies);
        },
        clear({ commit }) {
            commit('CLEAR');
        }
    };

    const mutations = {
        SET_TV_KEUZEHULP_VRAGEN: (state, vragen) => {
            state.vragen = vragen;
        },
        SET_TV_KEUZEHULP_ADVIEZEN: (state, adviezen) => {
            state.adviezen = adviezen;
        },
        CLEAR: state => {
            state.vragen = null;
        },
        SET_ADVIES: (state, advies) => {
            state.advies = advies;
        },
        SET_PENDING(state, value) {
            state.pending = value;
        },
        SET_ERROR(state, value) {
            state.error = value;
        }
    };

    return {
        namespaced: true,
        state,
        getters,
        actions,
        mutations
    };
};

export const tvKeuzehulpStoragePaths = ['tvkeuzehulp'];

export default app => createStore(app);
