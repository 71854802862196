import { APP_TYPE_PROSPECT, APP_TYPE_WIJZIGEN } from '@/constants';
import collectiviteitszoeker from '@/store/modules/collectiviteitszoeker';
import contracten from '@/store/modules/contracten';
import login from '@/store/modules/login';
import medischevragen from '@/store/modules/medischevragen';
import pakketten from '@/store/modules/pakketten';
import prefill from '@/store/modules/prefill';
import renderInfo from '@/store/modules/renderInfo';
import tvkeuzehulp from '@/store/modules/tvkeuzehulp';
import users from '@/store/modules/users';
import stappenplan from '@/store/modules/stappenplan';

const createStore = app => {
    const state = {
        userIndex: null,
        pending: false,
        pendingProducten: false,
        componentError: false,
        error: false
    };

    const getters = {
        getUserIndex: state => state.userIndex,
        getPendingStatus: state => state.pending,
        getPendingProductenStatus: state => state.pendingProducten,
        getComponentErrorStatus: state => state.componentError,
        getErrorStatus: (state, _getters, _rootState, rootGetters) => {
            return state.error || rootGetters['login/getErrorStatus'];
        }
    };

    const actions = {
        setUserIndex: ({ commit, dispatch, rootGetters }, index) => {
            commit('SET_USER_INDEX', index);

            // ophalen nieuwe pakketten indien nodig
            switch (rootGetters['renderInfo/getAppType']) {
                case APP_TYPE_PROSPECT:
                case APP_TYPE_WIJZIGEN:
                    dispatch('pakketten/ophalen');
                    break;
            }
        },
        setPendingProductenStatus: ({ commit }, payload) => {
            commit('SET_PENDING_PRODUCTEN', payload);
        },
        setPendingStatus: ({ commit }, payload) => {
            commit('SET_PENDING', payload);
        },
        setErrorStatus: ({ commit }, payload) => {
            commit('SET_ERROR', payload);
        },
        setComponentErrorStatus: ({ commit }, payload) => {
            commit('SET_COMPONENT_ERROR', payload);
        },
        // Bij wisselen van propositie OF app type (wijzigen of prospect) moet het volgende worden geleegd:
        // - pakketten: dit voorkomt dat er pakketten van de verkeerde funnel worden getoond (bv: ZieZo funnel die ZK pakketten toont).
        // - users: pakketten en pakketkeuzes zijn afhankelijk van elkaar.
        // - collectiviteitzoeker: zoekresultaten en allianties.
        // - collectiviteit: dit voorkomt dat pakketten worden opgehaald met een collectiviteitsnummer van een andere propositie (bv: ZieZo pakketten ophalen van met een AON collectiviteit)
        //   OF dat er een aanmelding wordt gedaan met een collectiviteitsnummer van een ander propositie (bv: ZieZo aanmelding met een AON collectiviteit)
        clearStore: ({ dispatch }) => {
            dispatch('pakketten/clear');
            dispatch('users/clear');
            dispatch('prefill/clearCollectiviteit');
            dispatch('pakketten/clearCollectiviteit');
            dispatch('collectiviteitszoeker/clearZoekResultaten');
            dispatch('collectiviteitszoeker/clearAllianties');
        }
    };

    const mutations = {
        SET_USER_INDEX: (state, index) => {
            state.userIndex = index;
        },
        SET_PENDING_PRODUCTEN(state, payload) {
            state.pendingProducten = payload;
        },
        SET_PENDING(state, payload) {
            state.pending = payload;
        },
        SET_ERROR(state, payload) {
            state.error = payload;
        },
        SET_COMPONENT_ERROR(state, payload) {
            state.componentError = { ...state.componentError, ...payload };
        }
    };

    const plugins = [];

    return {
        strict: process.env.NODE_ENV !== 'production',
        modules: {
            pakketten: pakketten(app),
            users: users(app),
            login: login(app),
            renderInfo: renderInfo(app),
            contracten: contracten(app),
            tvkeuzehulp: tvkeuzehulp(app),
            medischevragen,
            prefill: prefill(app),
            collectiviteitszoeker: collectiviteitszoeker(app),
            stappenplan: stappenplan(app)
        },
        plugins,
        state,
        getters,
        actions,
        mutations
    };
};

export default app => createStore(app);
