export function createSSRAppContext(state) {
    // For SSR, appBase and routerBase are always the same
    // AppBase is part of jss context by BasePathContextExtension
    // In editing mode use '/' for appBase path to handle /?sc_mode=edit... routes
    const { context } = state.sitecore;
    const appBase = context.pageEditing ? '/' : context.application.sitecorePath;

    const appContext = {
        routerBase: appBase,
        propositie: 'DPR', // experience editor propositie DPR dummy for now
        appBase,
        appStart: '', // start route niet relevant voor ssr experience editor
        siteName: context.site.name,
        siteVirtualFolder: '' // veranderd sinds 09-11-2022 door Stefan. Sitecore heeft geen virtual folder nodig (dit gooit in sommige gevallen zelfs errors). (OUD: altijd /zka/ voor ssr experience editor)
    };

    return appContext;
}

export function createVueAppContext(elementId) {
    const appContext = {};

    if (elementId) {
        const element = document.getElementById(elementId);
        for (const key in element.dataset) {
            appContext[key] = element.dataset[key];
        }
    }

    return appContext;
}
