import { LAND_NL, ROLE_PARTNER, ROLE_VERZEKERINGNEMER, REASONS } from '@/constants';
import { ApiClient } from '@/utils/apiClient';
import { clearDotsnCommas } from '@/utils/formatters';
import { isLeeftijdAangepast, isLeeftijdVanaf17Jaar10Maanden1Dag } from '@/utils/leeftijd';
import {
    getBetaaltermijnKortingsbedrag,
    getCollectiviteitskortingsbedrag,
    getTotaalbedrag
} from '@/utils/vuexHelpers';
import {
    isBsnValid,
    isEmailValid,
    isHouseNumberValid,
    isPostalCodeValid as isNLPostcodeValid
} from 'design.zorgdomein';
import { isInitialsValid, isSurnameValid } from 'design.zorgdomein';
import { isPostalCodeValid } from '@/utils/validators';
import { isDate } from '@/utils/date';
import { createSubState } from '@/utils/vuexHelpers';

const createStore = app => {
    const state = {
        verzekerden: {},
        pakketkeuzes: {},
        adres: {},
        adreszoeker: {},
        contact: {},
        aanvraag: {},
        bank: {},
        akkoord: {},
        medischevragen: {},
        incassodatums: [],
        landen: [],
        nationaliteiten: [],
        vergelijkbaretandverzekeringen: [],
        pending: false,
        error: false,
        success: false,
        delayed: false,
        offerte: {},
        juniorPakkettenErrorStatus: false,
        collectiviteitnummer: null,
        regions: null,
        showFamilyMemberModal: false,
        insuranceStartDate: createSubState()
    };
    const getters = {
        getPending: state => state.pending,
        getDelayed: state => state.delayed,
        getError: state => state.error,
        getSuccess: state => state.success,
        getUserIndex: (_state, _getters, _rootState, rootGetters) => rootGetters.getUserIndex,
        getUserIndexVerzekeringnemer: ({ verzekerden }) => {
            const keys = Object.keys(verzekerden);
            return keys.find(key => verzekerden[key].rol === ROLE_VERZEKERINGNEMER) || 1;
        },
        getUserIndexPartner: ({ verzekerden }) => {
            const keys = Object.keys(verzekerden);
            return keys.find(key => verzekerden[key].rol === ROLE_PARTNER) || 2;
        },
        hasPartner: ({ verzekerden }) => {
            const keys = Object.keys(verzekerden);
            return keys.some(key => verzekerden[key].rol === ROLE_PARTNER);
        },
        getUserIndexMaxValue: ({ verzekerden }) => {
            const keys = Object.keys(verzekerden).filter(Number);
            const maxIndex = Math.max(...keys);
            return maxIndex;
        },
        // pakketkeuzes getters
        getAllePakketkeuzes(state) {
            return state.pakketkeuzes;
        },
        getPakketKeuzes: (state, _getters, _rootState, rootGetters) => {
            return state.pakketkeuzes[rootGetters.getUserIndex] || null;
        },
        getPakketKeuzesByIndex: (state, _getters, _rootState, rootGetters) => index => {
            return state.pakketkeuzes[index] || null;
        },
        getPakketKeuzesVanVzn: (state, getters) =>
            state.pakketkeuzes[getters.getUserIndexVerzekeringnemer] || null,
        getPakketKeuze:
            (_state, getters) =>
            (type, index = null) => {
                let keuze =
                    index !== null
                        ? getters.getPakketKeuzesByIndex(index)?.[type]
                        : getters.getPakketKeuzes?.[type];
                keuze = keuze === undefined ? '' : keuze;
                return keuze;
            },
        getJuniorPakkettenErrorStatus(state) {
            return state.juniorPakkettenErrorStatus;
        },
        getCollectiviteitNummer: (_state, _getters, _rootState, rootGetters) => {
            const collectiviteit = rootGetters['pakketten/getCollectiviteit']?.afspraaknummer || '';
            return collectiviteit;
        },
        getIsRegistrationNumberObligated: (_state, _getters, _rootState, rootGetters) => {
            return rootGetters['pakketten/getCollectiviteit']?.isRegistratieNrVerplicht;
        },
        getSelectedRegion: (state, _getters, _rootState, rootGetters) => {
            return state.verzekerden[rootGetters['users/getUserIndex']]?.regio || null;
        },
        getProducts: (_state, _getters, _rootState, rootGetters) => {
            const products = rootGetters['pakketten/getProducts'] || null;
            return products;
        },
        getSelectedRegionByIndex: state => index => {
            return state.verzekerden[index]?.regio || null;
        },
        regions: state => {
            return state.regions;
        },
        heeftBasisverzekering: (_state, getters, _rootState, rootGetters) => {
            const value = getters.getPakketKeuzes?.hasOwnProperty('basis') || false;

            if (value === false) {
                const verzekerde = getters.getAlleVerzekerden[getters.getUserIndex];
                // Negeer geboortedatumcheck bij gebrek aan geboortedatum
                if (
                    !verzekerde?.geboortedatum ||
                    !isLeeftijdVanaf17Jaar10Maanden1Dag(verzekerde.geboortedatum)
                ) {
                    // User Story 2902261: [Storage] Prefill van WebAPI Verkoop operatie OphalenJuniorPakketten kost teveel tijd/resources
                    // Als het fout gaat tonen we een melding in het bonnetje.
                    // Validatie is voor kinderen niet nodig. Laat de WAPI het berekenen en juist vullen.
                    // Overschrijf het naar TRUE
                    return true;
                }
            }
            return value;
        },
        heeftIedereenBasisverzekering: (_state, getters) =>
            Object.keys(getters.getAlleVerzekerden).every(index =>
                getters.getAllePakketkeuzes[index]?.hasOwnProperty('basis')
            ),
        heeftPakketMutatie: (_state, getters, _rootState, rootGetters) => type => {
            const keuze = getters.getPakketKeuze(type);
            const initieel =
                rootGetters['pakketten/getInitieelProduct'](type)?.verkorteOmschrijving || '';
            return keuze !== initieel;
        },
        heeftEigenRisico: (_state, getters) => {
            return getters.getGekozenEigenRisicoCategorie !== -1;
        },
        heeftEigenRisicoMutatie: (_state, getters, _rootState, rootGetters) => {
            const keuze = getters.getPakketKeuze('eigenrisico');
            if (keuze === null) return false;
            const initieel = rootGetters['pakketten/getInitieelEigenRisico']?.categorie;
            return keuze !== initieel;
        },
        heeftMutaties: (_state, getters) => {
            const types = ['basis', 'combi', 'aanvullend', 'tand', 'dav'];
            return (
                types.some(type => getters.heeftPakketMutatie(type)) ||
                getters.heeftEigenRisicoMutatie
            );
        },
        heeftIemandBasisPakket: state => pakket => {
            const pakketkeuzes = state.pakketkeuzes;
            const keysPakketkeuzes = Object.keys(pakketkeuzes);
            const gevonden = keysPakketkeuzes.find(
                item => pakketkeuzes[item]?.['basis']?.toLowerCase() === pakket?.toLowerCase()
            );
            return gevonden !== undefined;
        },
        heeftIemandDigitaalBasisPakket: (_state, getters, _rootState, rootGetters) => {
            const digitaleBasispakketten = rootGetters[
                'pakketten/getDigitaleBasispakkettenByIndex'
            ](rootGetters.getUserIndex);
            if (!digitaleBasispakketten || digitaleBasispakketten.length === 0) return false;
            let digitaleBasispakkettenAanwezig = false;
            digitaleBasispakketten.forEach(digitaalBasispakket => {
                const digitaalPakketAanwezig = getters.heeftIemandBasisPakket(digitaalBasispakket);
                if (digitaalPakketAanwezig) {
                    digitaleBasispakkettenAanwezig = true;
                }
            });
            return digitaleBasispakkettenAanwezig;
        },
        getGekozenEigenRisicoCategorie: (_state, getters) => {
            const erKeuze = getters.getPakketKeuze('eigenrisico');
            if (erKeuze === null || erKeuze === '') return -1;
            return erKeuze;
        },
        getGekozenProduct:
            (_state, getters, _rootState, rootGetters) =>
            (type, index = null) => {
                const keuze = getters.getPakketKeuze(type, index);
                if (keuze) {
                    return rootGetters['pakketten/getProduct'](type)?.find(
                        bv => bv.verkorteOmschrijving === keuze
                    );
                }
                return null;
            },
        getNieuwePremie: (_state, getters, _rootState, rootGetters) => {
            return getTotaalbedrag(
                getters.getPakketKeuzes,
                rootGetters['pakketten/getProductsByIndex'](rootGetters.getUserIndex)
            );
        },
        getTotaalNieuwePremie: (state, getters, _rootState, rootGetters) => {
            let totaal = 0;
            Object.keys(getters.getAllePakketkeuzes).forEach(index => {
                const verzekerde = state.verzekerden[index];
                let bedrag = 0;

                // Negeer geboortedatumcheck bij gebrek aan geboortedatum
                if (
                    !verzekerde?.geboortedatum ||
                    isLeeftijdVanaf17Jaar10Maanden1Dag(verzekerde.geboortedatum)
                ) {
                    bedrag = getTotaalbedrag(
                        getters.getAllePakketkeuzes[index],
                        rootGetters['pakketten/getProductsByIndex'](index)
                    );
                }

                totaal += bedrag;
            });

            return totaal;
        },
        getBetaaltermijnKorting: (_state, getters, _rootState, rootGetters) => {
            return getBetaaltermijnKortingsbedrag(
                getters.getPakketKeuzes,
                rootGetters['pakketten/getProductsByIndex'](rootGetters.getUserIndex)
            );
        },
        getTotaalBetaaltermijnkorting: (state, getters, _rootState, rootGetters) => {
            let totaal = 0;

            Object.keys(getters.getAllePakketkeuzes).forEach(index => {
                const verzekerde = state.verzekerden[index];
                let bedrag = 0;

                // Negeer geboortedatumcheck bij gebrek aan geboortedatum
                if (
                    !verzekerde?.geboortedatum ||
                    isLeeftijdVanaf17Jaar10Maanden1Dag(verzekerde.geboortedatum)
                ) {
                    bedrag = getBetaaltermijnKortingsbedrag(
                        getters.getAllePakketkeuzes[index],
                        rootGetters['pakketten/getProductsByIndex'](index)
                    );
                }

                totaal += bedrag;
            });

            return totaal;
        },
        getCollectiviteitkorting: (_state, getters, _rootState, rootGetters) => {
            return getCollectiviteitskortingsbedrag(
                getters.getPakketKeuzes,
                rootGetters['pakketten/getProductsByIndex'](rootGetters.getUserIndex)
            );
        },
        getTotaalCollectiviteitkorting: (state, getters, _rootState, rootGetters) => {
            let totaal = 0;

            Object.keys(getters.getAllePakketkeuzes).forEach(index => {
                const verzekerde = state.verzekerden[index];
                let bedrag = 0;

                // Negeer geboortedatumcheck bij gebrek aan geboortedatum
                if (
                    !verzekerde?.geboortedatum ||
                    isLeeftijdVanaf17Jaar10Maanden1Dag(verzekerde?.geboortedatum)
                ) {
                    bedrag = getCollectiviteitskortingsbedrag(
                        getters.getAllePakketkeuzes[index],
                        rootGetters['pakketten/getProductsByIndex'](index)
                    );
                }

                totaal += bedrag;
            });

            return totaal;
        },

        // medische vragen getters
        heeftMedischeVragenBeantwoord: (state, _getters, _rootState, rootGetters) => {
            return state.medischevragen[rootGetters.getUserIndex] || null;
        },
        getAlleMedischeVragen(state) {
            return state.medischevragen;
        },
        getMedischeVragen: (state, _getters, _rootState, rootGetters) => {
            if (!state.medischevragen) return null;
            return state.medischevragen[rootGetters.getUserIndex] || null;
        },
        getRejectReason: (state, _getters, _rootState, rootGetters) => {
            return state.medischevragen[rootGetters.getUserIndex]?.reason || null;
        },
        getRejectReasonForUser: state => userIndex => {
            return state.medischevragen[userIndex]?.reason || null;
        },

        hasValidEmail: state => {
            // for now only an emailaddress is required
            return !!state.contact.email;
        },

        changedToDFRDigitalProduct: (_state, getters, _rootState, rootGetters) => {
            return (
                !rootGetters['renderInfo/isProspectFlow'] &&
                getters.heeftPakketMutatie('basis') &&
                getters.heeftIemandDigitaalBasisPakket &&
                rootGetters['renderInfo/getPropositie'] === 'DFR'
            );
        },

        // state validatie getters
        getIndexOntbrekendeVerzekerdeGegevens: state => {
            const keys = Object.keys(state.verzekerden);
            return keys.find(key => {
                const valid =
                    state.verzekerden[key]?.geboortedatum?.length > 0 &&
                    state.verzekerden[key]?.rol?.length > 0;
                return !valid;
            });
        },
        getIndexOntbrekendePakketgegevens: state => {
            const keys = Object.keys(state.verzekerden);
            return keys.find(key => {
                const verzekerde = state.verzekerden[key];

                // User Story 2902261: [Storage] Prefill van WebAPI Verkoop operatie OphalenJuniorPakketten kost teveel tijd/resources
                // Als het fout gaat tonen we een melding in het bonnetje.
                // Validatie is voor kinderen niet nodig. Laat de WAPI het berekenen en juist vullen.
                if (
                    !verzekerde?.geboortedatum ||
                    !isLeeftijdVanaf17Jaar10Maanden1Dag(verzekerde.geboortedatum)
                ) {
                    return false;
                }

                const valid =
                    state.pakketkeuzes[key]?.hasOwnProperty('basis') &&
                    state.pakketkeuzes[key]?.['eigenrisico'] !== -1;
                return !valid;
            });
        },
        getIndexOntbrekendeMedischeAcceptatie: (state, getters, _rootState, rootGetters) => {
            const keys = Object.keys(state.verzekerden);
            return keys.find(key => {
                if (
                    state?.pakketkeuzes[key]?.tand &&
                    rootGetters['pakketten/getProductByValue'](
                        'tand',
                        state?.pakketkeuzes[key]?.tand
                    ).medischeAcceptatieNodig === true &&
                    !state.medischevragen[key] &&
                    state.verzekerden[key]?.geboortedatum &&
                    isLeeftijdVanaf17Jaar10Maanden1Dag(state.verzekerden[key].geboortedatum) &&
                    !getters.isUitgezonderdeFrieseJongere(key)
                ) {
                    return key;
                }
            });
        },
        isUitgezonderdeFrieseJongere: (_state, _getters, _rootState, rootGetters) => index => {
            if (rootGetters['renderInfo/getPropositie'] !== 'DFR') {
                return false;
            }
            return rootGetters['pakketten/getIsRondom18ByIndex'](index);
        },
        getCurrentUserNeedMedicalAcceptance: (state, getters, _rootState, rootGetters) => {
            if (getters.isUitgezonderdeFrieseJongere(getters.getUserIndex)) return false;
            return (
                rootGetters['pakketten/getProductByValue'](
                    'tand',
                    state?.pakketkeuzes[getters.getUserIndex]?.tand
                )?.medischeAcceptatieNodig === true
            );
        },
        getNextUserNeedMedicalAcceptance: (state, getters, _rootState, rootGetters) => {
            const keys = Object.keys(state.verzekerden);
            const currentUser = getters.getUserIndex;
            const nextUser = keys.find(key => {
                if (parseInt(key) === parseInt(currentUser)) return false;
                return (
                    state?.pakketkeuzes[key]?.tand &&
                    rootGetters['pakketten/getProductByValue'](
                        'tand',
                        state?.pakketkeuzes[key]?.tand
                    ).medischeAcceptatieNodig === true &&
                    !state.medischevragen[key] &&
                    state.verzekerden[key]?.geboortedatum &&
                    isLeeftijdVanaf17Jaar10Maanden1Dag(state.verzekerden[key].geboortedatum) &&
                    !getters.isUitgezonderdeFrieseJongere(key)
                );
            });

            return nextUser ? state.verzekerden[nextUser] : undefined;
        },
        getIndexOntbrekendeVerzekerdePersoonsgegevens: state => {
            const keys = Object.keys(state.verzekerden);
            return keys.find(key => {
                const valid =
                    isInitialsValid(state.verzekerden[key]?.voorletters) &&
                    isSurnameValid(state.verzekerden[key]?.achternaam) &&
                    state.verzekerden[key]?.geslacht?.length > 0 &&
                    isBsnValid(state.verzekerden[key]?.bsn.toString()) &&
                    state.verzekerden[key]?.inkomen?.length > 0;
                return !valid;
            });
        },
        getValidatiePostcode: (_state, getters) => postcode => {
            if (!postcode) return false;

            // check for correct dutch postal code
            const land = getters.getLand;
            if (land === LAND_NL) return isNLPostcodeValid(postcode);

            const landen = getters.getLanden;
            if (landen.length === 0) return false;

            // check for  correct foreign postal code
            const postcodeRegex = landen.find(({ code }) => land === code)?.postcodeFormaat;
            return isPostalCodeValid(postcode, postcodeRegex);
        },
        hasRegistrationNumberAndRightToCollectivity: _state => {
            const insuredPersons = Object.values(_state.verzekerden);

            if (!insuredPersons || insuredPersons.length === 0) {
                // Object.values returns null, undefined or an empty array.
                return false;
            } else {
                return insuredPersons.every(
                    insured =>
                        !insured.isRechthebbendeOpCollectiviteit ||
                        insured.registratienummer.trim() !== ''
                );
            }
        },

        getHeeftOntbrekendeOverigePersoonsgegevens: (_state, getters) => {
            const isRequiredToFillInRegistrationNumber = getters.getIsRegistrationNumberObligated
                ? getters.hasRegistrationNumberAndRightToCollectivity
                : true;
            const isRechthebbendeMetCollectiviteit =
                getters.getCollectiviteitNr && getters.getRechthebbenden;

            const valid =
                getters.getEmail &&
                getters.getAanvraagReden?.length > 0 &&
                getters.getAanvraagDatum?.length > 0 &&
                getters.getStraatnaam?.length > 0 &&
                getters.getHuisnummer &&
                getters.getValidatiePostcode(getters.getPostcode) &&
                getters.getWoonplaats?.length > 0 &&
                getters.getLand?.length > 0 &&
                getters.getAkkoordContact &&
                ((isRechthebbendeMetCollectiviteit && isRequiredToFillInRegistrationNumber) ||
                    !getters.getCollectiviteitNr) &&
                getters.getIsValidNewProduct;
            return !valid;
        },
        getHasNewProduct(_state, getters, _rootState, rootGetters) {
            return !!getters.getNewProductName;
        },
        getNewProductName(_state, getters, _rootState, rootGetters) {
            let newProductName = null;
            const pakketkeuzes = getters.getAllePakketkeuzes;
            const types = ['basis', 'combi', 'aanvullend', 'tand', 'dav'];

            const getPakket = (pakketkeuzes, key, type) => {
                const pakketten = rootGetters['pakketten/getProductsByIndex'](key);
                return (
                    pakketten?.[type] &&
                    pakketten?.[type].find(
                        p => p?.verkorteOmschrijving === pakketkeuzes[key]?.[type]
                    )
                );
            };
            for (const key in pakketkeuzes) {
                if (newProductName) break;
                types.forEach(t => {
                    const pakket = getPakket(pakketkeuzes, key, t);
                    if (pakket?.isNieuw) {
                        newProductName = pakket.naam;
                    }
                });
            }
            return newProductName;
        },
        getIsValidNewProduct(_state, getters, _rootState, rootGetters) {
            const minDatumNewProduct = `${rootGetters['renderInfo/getPremiejaar']}-01-01`;
            return (
                !getters.getHasNewProduct ||
                new Date(getters.getAanvraagDatum) >= new Date(minDatumNewProduct)
            );
        },
        // Since we check the starting date afterward, it might differ from the one the user entered.
        getMayHavePreviousPaymentTerms(_state, getters) {
            return (
                getters.getAanvraagReden === REASONS.EX_ASIELZOEKER ||
                getters.getAanvraagReden === REASONS.EX_MILITAIR ||
                getters.getAanvraagReden === REASONS.EX_GEWETENS_BEZWAARDE ||
                getters.getAanvraagReden === REASONS.VERHUIS_NAAR_NEDERLAND
            );
        },

        // verzekerde getters
        getAlleVerzekerden: state => state.verzekerden,
        getVerzekerdenEntries: state => Object.entries(state.verzekerden),
        getVerzekerde: (state, _getters, _rootState, rootGetters) =>
            state.verzekerden[rootGetters.getUserIndex] || '',
        getVoorletters: (state, _getters, _rootState, rootGetters) =>
            state.verzekerden[rootGetters.getUserIndex]?.voorletters || '',
        getAchternaam: (state, _getters, _rootState, rootGetters) =>
            state.verzekerden[rootGetters.getUserIndex]?.achternaam || '',
        getGeslacht: (state, _getters, _rootState, rootGetters) =>
            state.verzekerden[rootGetters.getUserIndex]?.geslacht || '',
        getBsn: (state, _getters, _rootState, rootGetters) =>
            state.verzekerden[rootGetters.getUserIndex]?.bsn || '',
        getRol: (state, _getters, _rootState, rootGetters) =>
            state.verzekerden[rootGetters.getUserIndex]?.rol || '',
        getGeboortedatum: (state, _getters, _rootState, rootGetters) =>
            state.verzekerden[rootGetters.getUserIndex]?.geboortedatum || '',
        getInkomen: (state, _getters, _rootState, rootGetters) =>
            state.verzekerden[rootGetters.getUserIndex]?.inkomen || '',
        getRechthebbenden: state =>
            Object.values(state.verzekerden).some(
                verzekerde => verzekerde.isRechthebbendeOpCollectiviteit
            ),

        // adres getters
        getAdres: state => state.adres,
        getStraatnaam: state => state.adres.straatnaam || '',
        getHuisnummer: state => state.adres.huisnummer || '',
        getToevoeging: state => state.adres.toevoeging || '',
        getPostcode: state => state.adres.postcode || '',
        getWoonplaats: state => state.adres.woonplaats || '',
        getLand: state => state.adres.land || LAND_NL,
        getLandGegevens: (state, getters) =>
            state.landen.find(({ code }) => code === getters.getLand),

        // Adreszoeker getters
        getAdreszoeker: state => state.adreszoeker,

        // contact getters
        getContact: state => state.contact,
        getEmail: state => state.contact.email || '',
        getMobiel: state => state.contact.mobiel || '',

        // aanvraag getters
        getAanvraagReden: state => state.aanvraag.reden || '',
        getAanvraagGegevens: state => state.aanvraag.gegevens || '',
        getAanvraagDatum: state => state.aanvraag.datum || '',
        getDatumNieuwDienstverband: state => state.aanvraag.datumNieuwDienstverband || '',
        getPolisbladOntvangstdatum: state => state.aanvraag.polisbladOntvangstdatum || '',
        getDatumZorgpas: state => state.aanvraag.datumZorgpas || '',
        getEersteIncassodatum: state => state.aanvraag.eersteIncassodatum || '',
        getToonVersneldeIncasso: state => state.aanvraag.toonVersneldeIncasso || false,
        getGezinsPremie: state => state.aanvraag.gezinsPremie || 0,
        getOpzeggenVorigeVerzekering: state => state.aanvraag.opzeggenVorigeVerzekering || '0',
        getVerzekeringOpgezegd: state => state.aanvraag.verzekeringOpgezegd || null,
        getTransactieId: state => state.aanvraag.transactieId || '',
        getEersteIncassoMeerdereTermijnen: state =>
            state.aanvraag.eersteIncassoMeerdereTermijnen || false,

        // Offerte getters
        getOffertePending: state => !!state.offerte.pending,
        getOfferteError: state => !!state.offerte.error,
        getOfferteSuccess: state => !!state.offerte.success,
        getOfferteEmail: state => state.offerte?.email || '',
        getOfferteTerugkeerUrl: state => state.offerte?.terugkeerUrl || '',

        // bankgegevens getters
        getBank: state => state.bank,
        getIban: state => state.bank.iban || '',
        getBic: state => state.bank.bic || '',
        getGespreidEr: state => state.bank.gespreidEr || '',
        getBetaalwijze: state => state.bank.betaalwijze ?? null,
        getBetaaltermijn: state => state.bank.betaaltermijn ?? null,
        getAfschrijfdatum: state => state.bank.afschrijfdatum ?? null,
        getHeeftNuAI: state => state.bank.heeftNuAI ?? null,

        // akkoord getters
        getAkkoord: state => state.akkoord,
        getAkkoordBorderel: state => state.akkoord.borderel || null,
        getAkkoordZiekenhuizen: state => state.akkoord.ziekenhuizen || false,
        getAkkoordDocumenten: state => state.akkoord.documenten || false,
        getAkkoordContact: state => state.akkoord.contact || false,
        getAkkoordAlgemeneVoorwaarden: state => state.akkoord.algemeneVoorwaarden || false,

        // lijsten getters
        getIncassodatums: state => state.incassodatums,
        getLanden: state => state.landen,
        getVergelijkbareTandverzekeringen: state => state.vergelijkbaretandverzekeringen,
        getNationaliteiten: state => state.nationaliteiten,

        getCollectiviteitNr: state => state.collectiviteitnummer,
        getBvNaamVoorOverzicht: state => state.bvNaamVoorOverzicht,
        showFamilyMemberModal: state => {
            return state.showFamilyMemberModal;
        },
        isNBPSelected: (state, getters) => {
            return Object.keys(state.verzekerden).some(
                index =>
                    getters.getGekozenProduct('basis', parseInt(index))?.verkorteOmschrijving ===
                    'ZVWP'
            );
        },
        //Insurancestartdate getters
        getInsuranceStartDateAsDate: state => key => {
            // insuranceStartDate is a string when persisted, so convert it to a date
            const value = state.insuranceStartDate.data?.[key];
            return value ? new Date(value) : null;
        },
        getInsuranceStartDateHV: (_state, getters) => {
            return getters.getInsuranceStartDateAsDate('HV');
        },
        getInsuranceStartDateAV: (_state, getters) => {
            return getters.getInsuranceStartDateAsDate('AV');
        },
        getInsuranceStartDateER: (_state, getters) => {
            return getters.getInsuranceStartDateAsDate('ER');
        },
        getInsuranceStartDateTV: (_state, getters) => {
            return getters.getInsuranceStartDateAsDate('TV');
        },
        getInsuranceStartDateDAV: (_state, getters) => {
            return getters.getInsuranceStartDateAsDate('DAV');
        },
        getInsuranceStartDatePending: state => {
            return state.insuranceStartDate.pending;
        },
        getInsuranceStartDateError: state => {
            return state.insuranceStartDate.error;
        },
        getInsuranceStartDateValues: (state, getters) => {
            // Filter out null values
            const valuesToCheck = [
                getters.getInsuranceStartDateAsDate('HV'),
                getters.getInsuranceStartDateAsDate('ER'),
                getters.getInsuranceStartDateAsDate('AV'),
                getters.getInsuranceStartDateAsDate('DAV'),
                getters.getInsuranceStartDateAsDate('TV')
            ].filter(value => isDate(value));
            return valuesToCheck;
        },
        getInsuranceStartDate: (_state, getters) => {
            return getters.getInsuranceStartDateValues?.[0] ?? null;
        },
        areInsuranceStartDatesSame: (_state, getters) => {
            const valuesToCheck = getters.getInsuranceStartDateValues;
            const firstValueTime = valuesToCheck?.[0]?.getTime();
            return (
                !isNaN(firstValueTime) && // There should be a first value AND
                valuesToCheck.every(value => value.getTime() === firstValueTime) // All values should be the same
            );
        }
    };

    const actions = {
        clear({ commit }) {
            commit('CLEAR');
        },
        clearUser: ({ commit }, index) => {
            commit('CLEAR_VERZEKERDE', { index });
            commit('CLEAR_PAKKETKEUZE', { index });
        },
        clearUsers: ({ commit }) => {
            commit('CLEAR_VERZEKERDEN');
        },
        clearPakketkeuzes({ commit }) {
            commit('CLEAR_PAKKETKEUZES');
        },
        clearRegistratieNummers({ commit }) {
            commit('CLEAR_REGISTRATIE_NUMMERS');
        },
        clearRechthebbenden({ commit }) {
            commit('CLEAR_RECHTHEBBENDEN');
        },
        clearAanvraag({ commit }) {
            commit('CLEAR_AANVRAAG');
        },
        async aanvragenOfferte({ commit, getters }) {
            try {
                commit('SET_OFFERTE_PROP', { key: 'pending', value: true });
                commit('SET_OFFERTE_PROP', { key: 'error', value: false });

                const appContext = app.config.globalProperties.$jss.appContext();

                //map verzekerden met index
                const alleVerzekerden = getters.getAlleVerzekerden;
                const keysVerzekerden = Object.keys(alleVerzekerden);
                const verzekerden = keysVerzekerden.map(item => {
                    return { ...alleVerzekerden[item], index: item };
                });

                //map pakketkeuzes met index
                const allePakketkeuzes = getters.getAllePakketkeuzes;
                const keysPakketkeuzes = Object.keys(allePakketkeuzes);
                const pakketkeuzes = keysPakketkeuzes.map(item => {
                    return { ...allePakketkeuzes[item], index: item };
                });

                const { data } = await ApiClient(appContext).post(
                    `/clientcontroller/salesfunnelclient/aanvragenofferte`,
                    {
                        Collectiviteitnummer: getters.getCollectiviteitNummer,
                        Verzekerden: verzekerden,
                        Pakketkeuzes: pakketkeuzes,
                        Betaaltermijn: getters.getBetaaltermijn?.soort,
                        Propositie: appContext.propositie,
                        Email: getters.getOfferteEmail,
                        TerugNaarSalesfunnelLink: getters.getOfferteTerugkeerUrl
                    }
                );

                if (data?.isSucces && data?.errorCode === 'delayed') {
                    console.log('offerte is delayed');
                    commit('SET_DELAYED', true);
                } else if (data?.isSucces) {
                    commit('SET_OFFERTE_PROP', { key: 'success', value: true });
                } else {
                    commit('SET_OFFERTE_PROP', { key: 'error', value: true });
                }
            } catch (error) {
                commit('SET_OFFERTE_PROP', { key: 'error', value: true });
            } finally {
                commit('SET_OFFERTE_PROP', { key: 'pending', value: false });
            }
        },
        async aanvragenVerzekering({ commit, getters, rootGetters }) {
            try {
                commit('SET_ERROR', false);
                commit('SET_ERROR', false, { root: true }); // tijdelijk globale error
                commit('SET_PENDING', true);
                commit('SET_DELAYED', false);

                const appContext = app.config.globalProperties.$jss.appContext();

                //map verzekerden met index
                const alleVerzekerden = getters.getAlleVerzekerden;
                const keysVerzekerden = Object.keys(alleVerzekerden);
                const verzekerden = keysVerzekerden.map(item => {
                    return { ...alleVerzekerden[item], index: item };
                });

                //map pakketkeuzes met index
                const allePakketkeuzes = getters.getAllePakketkeuzes;
                const keysPakketkeuzes = Object.keys(allePakketkeuzes);
                const pakketkeuzes = keysPakketkeuzes.map(item => {
                    return { ...allePakketkeuzes[item], index: item };
                });
                const contact = {
                    ...getters.getContact,
                    mobiel: getters.getContact.mobiel?.replace(/\D/g, '')
                };
                const bank = {
                    iban: getters.getIban,
                    betaaltermijn: getters.getBetaaltermijn?.soort,
                    gewensteIncassodag: getters.getAfschrijfdatum?.incassoDag
                };

                if (getters.getBic) {
                    bank.bic = getters.getBic;
                }

                if (getters.getBetaalwijze) {
                    bank.betaalwijze = getters.getBetaalwijze;
                }

                const medischeVragenMapped = {};

                keysVerzekerden.map(key => {
                    if (getters.getAlleMedischeVragen[key]) {
                        medischeVragenMapped[getters.getAlleVerzekerden[key]?.bsn] =
                            getters.getAlleMedischeVragen[key];
                    }
                });

                const { data } = await ApiClient(appContext).post(
                    `/clientcontroller/salesfunnelclient/aanvragenverzekering`,
                    {
                        Verzekerden: verzekerden,
                        Pakketkeuzes: pakketkeuzes,
                        Adres: getters.getAdres,
                        Contact: contact,
                        Bank: bank,
                        Akkoord: getters.getAkkoord,
                        Propositie: appContext.propositie,
                        Collectiviteitnummer: getters.getCollectiviteitNummer,
                        RedenInschrijvingSalesfunnel: getters.getAanvraagReden,
                        Ingangsdatum: getters.getAanvraagDatum,
                        DatumNieuwDienstverband: getters.getDatumNieuwDienstverband,
                        KlantwensBijOpzeggenVorigeVerzekering: getters.getOpzeggenVorigeVerzekering,
                        MedischeVragen: medischeVragenMapped
                            ? JSON.stringify(medischeVragenMapped)
                            : null
                    }
                );

                // Datalayer Relay 42
                const betaalTermijn = getters.getBetaaltermijn;
                const calculateMaandPremie = () => {
                    // 12 = maand, 4 = kwartaal, 2 = halfjaar, 1 = jaar
                    if (betaalTermijn.soort === 1) return getters.getTotaalNieuwePremie / 12;
                    if (betaalTermijn.soort === 2) return getters.getTotaalNieuwePremie / 6;
                    if (betaalTermijn.soort === 4) return getters.getTotaalNieuwePremie / 3;
                    return getters.getTotaalNieuwePremie;
                };
                const setPaymentTermText = () => {
                    // 12 = maand, 4 = kwartaal, 2 = halfjaar, 1 = jaar
                    if (betaalTermijn.soort === 1) return 'jaar';
                    if (betaalTermijn.soort === 2) return 'half jaar';
                    if (betaalTermijn.soort === 4) return 'kwartaal';
                    return 'maand';
                };
                const verzekerdeInfo = [];

                Object.values(getters.getAllePakketkeuzes).map(keuzes => {
                    const basis = rootGetters['pakketten/getProduct']('basis')?.find(
                        p => p.verkorteOmschrijving === keuzes.basis
                    );
                    const tand = rootGetters['pakketten/getProduct']('tand')?.find(
                        p => p.verkorteOmschrijving === keuzes.tand
                    );
                    const aanvullend = rootGetters['pakketten/getProduct']('aanvullend')?.find(
                        p => p.verkorteOmschrijving === keuzes.aanvullend
                    );
                    const combi = rootGetters['pakketten/getProduct']('combi')?.find(
                        p => p.verkorteOmschrijving === keuzes.combi
                    );
                    const extra = rootGetters['pakketten/getProduct']('dav')?.find(
                        p => p.verkorteOmschrijving === keuzes.dav
                    );

                    return verzekerdeInfo.push({
                        basisVerzekering: basis ? basis.verkorteOmschrijving : '',
                        basisVerzekeringPrijs: basis?.nettoPremie
                            ? clearDotsnCommas(basis?.nettoPremie)
                            : '0',
                        eigenRisico: keuzes?.eigenrisicoCode ? String(keuzes.eigenrisicoCode) : '',
                        combi: combi ? combi.verkorteOmschrijving : '',
                        combiPrijs: combi?.nettoPremie ? clearDotsnCommas(combi.nettoPremie) : '0',
                        aanvullend: aanvullend ? aanvullend.verkorteOmschrijving : '',
                        aanvullendPrijs: aanvullend?.nettoPremie
                            ? clearDotsnCommas(aanvullend.nettoPremie)
                            : '0',
                        tandarts: tand ? tand.verkorteOmschrijving : '',
                        tandartsPrijs: tand?.nettoPremie ? clearDotsnCommas(tand.nettoPremie) : '0',
                        extra: extra ? extra.dav : '',
                        extraPrijs: extra?.nettoPremie ? clearDotsnCommas(extra.nettoPremie) : '0'
                    });
                });

                if (data?.errorCode === 'delayed') {
                    commit('SET_DELAYED', true);
                }

                if (data?.isSucces || data?.errorCode === 'delayed') {
                    // opslaan timeline data in store
                    commit('SET_AANVRAAG_PROP', {
                        key: 'polisbladOntvangstdatum',
                        value: data.timelineData?.polisbladOntvangstdatum
                    });
                    commit('SET_AANVRAAG_PROP', {
                        key: 'eersteIncassodatum',
                        value: data.timelineData?.eersteIncassodatum
                    });
                    commit('SET_AANVRAAG_PROP', {
                        key: 'datumZorgpas',
                        value: data.timelineData?.datumZorgpas
                    });
                    commit('SET_AANVRAAG_PROP', {
                        key: 'toonVersneldeIncasso',
                        value: data.timelineData?.toonVersneldeIncasso
                    });
                    commit('SET_AANVRAAG_PROP', {
                        key: 'eersteIncassoMeerdereTermijnen',
                        value: data.timelineData?.eersteIncassoMeerdereTermijnen
                    });
                    commit('SET_AANVRAAG_PROP', {
                        key: 'gezinsPremie',
                        value: getters.getTotaalNieuwePremie
                    });

                    // verzekerde info
                    commit('SET_AANVRAAG_PROP', {
                        key: 'transactieId',
                        value: data.transactieId
                    });
                    commit('SET_AANVRAAG_PROP', {
                        key: 'gegevens',
                        value: {
                            aantalVerzekerden: String(keysPakketkeuzes.length),
                            betaalTermijn: setPaymentTermText(),
                            gezinsPremie: calculateMaandPremie().toFixed(2),
                            verzekerdeInfo: verzekerdeInfo
                        }
                    });

                    // borderel info
                    commit('SET_AANVRAAG_PROP', {
                        key: 'pakketten',
                        value: {
                            collectiviteit: getters.getCollectiviteit
                        }
                    });
                    // wis zoveel mogelijk gebruiker gegevens uit store
                    commit('PARTIAL_CLEAR');
                    commit('CLEAR_ADRESZOEKER');
                } else {
                    commit('SET_ERROR', true);
                    commit('SET_ERROR', true, { root: true }); // tijdelijk globale error
                }
            } catch (error) {
                console.log('Aanvragen verzekering niet gelukt:', error.message);
                commit('SET_ERROR', true);
                commit('SET_ERROR', true, { root: true }); // tijdelijk globale error
            } finally {
                commit('SET_PENDING', false);
            }
        },
        async wijzigingDoorvoeren({ commit, getters, rootGetters }) {
            const appContext = app.config.globalProperties.$jss.appContext();

            commit('SET_ERROR', false);
            commit('SET_SUCCESS', false);
            commit('SET_PENDING', true);

            try {
                await ApiClient(appContext).post(
                    `/clientcontroller/salesfunnelclient/wijzigpakketten`,
                    {
                        Index: rootGetters.getUserIndex,
                        Basis: getters.getPakketKeuze('basis'),
                        EigenRisicoCode: getters.getGekozenEigenRisicoCategorie,
                        Combi: getters.getPakketKeuze('combi'),
                        Aanvullend: getters.getPakketKeuze('aanvullend'),
                        Tand: getters.getPakketKeuze('tand'),
                        Dav: getters.getPakketKeuze('dav'),
                        Email: getters.getEmail,
                        MedischeVragen: getters.getMedischeVragen
                            ? JSON.stringify(getters.getMedischeVragen)
                            : null,
                        Propositie: appContext.propositie,
                        FromStappenplan: rootGetters['renderInfo/getAppType'] === 'stappenplan'
                    }
                );

                commit('SET_SUCCESS', true);
            } catch (error) {
                console.log('Error during mutation endpoint:', error.message);
                commit('SET_ERROR', true);
            } finally {
                commit('SET_PENDING', false);
            }
        },
        async ophalenVergelijkbareTandVerzekeringen({ dispatch, commit, rootGetters }) {
            const appContext = app.config.globalProperties.$jss.appContext();

            try {
                // ophalen vergelijkbare tandverzekeringen
                const pakketkeuze = rootGetters['users/getPakketKeuzes'];
                const { data } = await ApiClient(appContext).post(
                    `/clientcontroller/salesfunnelclient/ophalenvergelijkbaretandverzekeringen`,
                    {
                        Propositie: appContext.propositie,
                        PakketCode: pakketkeuze.tand
                    }
                );

                if (data?.isSucces) {
                    commit('SET_TANDVERZEKERINGEN', data.vergelijkbareTandverzekeringen);
                } else {
                    console.log('Kan geen vergelijkbare tandverzekeringen ophalen');
                    dispatch('setErrorStatus', true, { root: true });
                }
            } catch (error) {
                console.log('Kan geen vergelijkbare tandverzekeringen ophalen:', error.message);
                dispatch('setErrorStatus', true, { root: true });
            } finally {
                dispatch('setPendingStatus', false, { root: true });
            }
        },
        async ophalenJuniorPakketten({ getters, rootGetters, dispatch, commit }) {
            if (!rootGetters['renderInfo/isProspectFlow']) return;

            const appContext = app.config.globalProperties.$jss.appContext();

            // map verzekerden
            const verzekerdenEntries = rootGetters['users/getVerzekerdenEntries'];
            const verzekerden = verzekerdenEntries
                .map(([key, verzekerde]) =>
                    (({ geboortedatum, rol }) => ({ geboortedatum, rol, index: key }))(verzekerde)
                )
                .filter(v => v.index < 3);

            // map pakketkeuzes
            const pakketkeuzesEntries = Object.entries(rootGetters['users/getAllePakketkeuzes']);
            const pakketkeuzes = pakketkeuzesEntries
                .map(([key, pakket]) =>
                    (({ basis, combi, aanvullend, dav, tand }) => ({
                        basis,
                        combi,
                        aanvullend,
                        dav,
                        tand,
                        index: key
                    }))(pakket)
                )
                .filter(v => v.index < 3);
            const iedereenBasis = pakketkeuzes.every(k => k.basis);

            if (verzekerden && pakketkeuzes && pakketkeuzes.length > 0 && iedereenBasis) {
                dispatch('setPendingStatus', true, { root: true });
                dispatch('setErrorStatus', false, { root: true });
                try {
                    // ophalen juniorpakketten
                    const { data } = await ApiClient(appContext).post(
                        `/clientcontroller/salesfunnelclient/ophalenjuniorpakketten`,
                        {
                            Ingangsdatum: null,
                            Propositie: appContext.propositie,
                            Collectiviteitnummer: getters.getCollectiviteitNummer,
                            Pakketkeuzes: pakketkeuzes,
                            Verzekerden: verzekerden
                        }
                    );
                    if (data?.isSucces) {
                        const junioren = verzekerdenEntries
                            .map(([key, verzekerde]) =>
                                (({ geboortedatum }) => ({ geboortedatum, index: key }))(verzekerde)
                            )
                            .filter(
                                v =>
                                    v.index > 2 &&
                                    !isLeeftijdVanaf17Jaar10Maanden1Dag(v.geboortedatum)
                            );
                        junioren.forEach(junior => {
                            commit('SET_PAKKETKEUZES', {
                                index: junior.index,
                                pakketkeuzes: data.pakketkeuze
                            });
                        });
                    } else {
                        console.log('Kan geen juniorpakketten ophalen');
                        commit('SET_JUNIOR_PAKKETEN_ERROR_STATUS', true);
                    }
                } catch (error) {
                    console.log('Kan geen juniorpakketten ophalen:', error.message);
                    commit('SET_JUNIOR_PAKKETEN_ERROR_STATUS', true);
                } finally {
                    dispatch('setPendingStatus', false, { root: true });
                }
            }
        },
        async getRegions({ dispatch, commit }) {
            // regions are already in cache, abort
            if (state.regions !== null) return;

            try {
                const appContext = app.config.globalProperties.$jss.appContext();
                const { data } = await ApiClient(appContext).get(
                    `/clientcontroller/salesfunnelclient/ophalenregios`
                );

                dispatch('setPendingStatus', true, { root: true });
                dispatch('setErrorStatus', false, { root: true });
                commit('SET_REGIONS', data);
            } catch (error) {
                console.log('Kan geen regios ophalen:', error.message);
            } finally {
                dispatch('setPendingStatus', false, { root: true });
            }
        },

        selectRegion({ commit, rootGetters }, region) {
            commit('SET_VERZEKERDE_PROP', {
                index: rootGetters['users/getUserIndex'] || rootGetters.getUserIndex,
                key: 'region',
                region
            });
        },

        toepassenMedischeAcceptatie({ getters, rootGetters, dispatch, state }) {
            // Ga alle verzekerden af
            Object.keys(state.verzekerden).forEach(index => {
                const medischeAfwijsreden = rootGetters['users/getRejectReasonForUser'](index);
                if (medischeAfwijsreden === null) return;

                // Indien afgewezen, blokkeer pakketten die medische accepatie vereisen
                const medischeAcceptatiePakketten = rootGetters['pakketten/getProductsByIndex'](
                    index
                )
                    ?.tand.filter(pakket => pakket.medischeAcceptatieNodig === true)
                    .map(pakket => pakket.verkorteOmschrijving);
                if (medischeAcceptatiePakketten) {
                    // Blokkeer voor de gebruiker waar we in zitten
                    medischeAcceptatiePakketten.userIndex = `${index}-${getters.getBetaaltermijn?.soort}`;
                    dispatch(
                        'pakketten/setDisabledTandPakkettenForUser',
                        medischeAcceptatiePakketten,
                        { root: true }
                    );
                }
            });
        },
        bepaalOphalenJuniorPakketten: ({ rootGetters, dispatch }) => {
            // bepaal of er een minderjarig kind op de polis aanwezig is
            const verzekerdenEntries = rootGetters['users/getVerzekerdenEntries'];
            const junioren = verzekerdenEntries
                .map(([key, verzekerde]) =>
                    (({ geboortedatum }) => ({ geboortedatum, index: key }))(verzekerde)
                )
                .find(
                    v =>
                        v.index > 2 &&
                        !!v.geboortedatum &&
                        !isLeeftijdVanaf17Jaar10Maanden1Dag(v.geboortedatum)
                );

            // zo ja, haal juniorpakketten op mbv de pakketten van verzekeringnemer en partner en sla ze op in de store
            if (junioren) {
                dispatch('ophalenJuniorPakketten');
            }
        },

        clearPakketkeuzeWhenUnavailable: ({ commit, getters, rootGetters }, index) => {
            const pakketKeuzes = rootGetters['users/getPakketKeuzesByIndex'](index);
            if (
                pakketKeuzes?.basis &&
                !getters.getProducts?.basis.find(
                    b => b.verkorteOmschrijving === pakketKeuzes?.basis
                )
            ) {
                commit('CLEAR_PAKKETKEUZE', { index });
            }
        },

        // addSelectedProduct kan vervangen worden door setPakketSelectie
        addSelectedProduct: ({ commit, rootGetters }, pakketkeuze) => {
            const type = Object.keys(pakketkeuze)[0];
            const heeftEigenRisico = rootGetters['users/heeftEigenRisico'];

            commit('SET_PAKKETKEUZES', {
                index: rootGetters.getUserIndex,
                pakketkeuzes: pakketkeuze
            });

            // Preselect ER after BV is chosen
            // ER should always be chosen
            // ER code vervangt uiteindelijk ER categorie. Voorlopig blijven beiden nog bestaan.
            if (type === 'basis' && !heeftEigenRisico) {
                const { eigenRisicos } = rootGetters['pakketten/getEigenRisico'];
                const eigenrisico = Number(eigenRisicos[0].categorie);
                const eigenrisicoCode = Number(eigenRisicos[0].code);

                commit('SET_PAKKETKEUZES', {
                    index: rootGetters.getUserIndex,
                    pakketkeuzes: { eigenrisico, eigenrisicoCode }
                });
            }
        },
        setPakketSelectie: ({ commit, rootGetters }, pakketkeuzes) => {
            commit('SET_PAKKETKEUZES', { index: rootGetters.getUserIndex, pakketkeuzes });
        },
        copyPakketSelectieVanVzn: ({ getters, commit }, index) => {
            commit('SET_PAKKETKEUZES', {
                index,
                pakketkeuzes: getters.getPakketKeuzesVanVzn
            });
        },
        setTotaalPakketSelectie: ({ commit }, pakketkeuzes) => {
            commit('SET_TOTAAL_PAKKETKEUZES', pakketkeuzes);
        },
        setCollectiviteitNr: ({ commit }, collectiviteitnr) => {
            commit('SET_GEKOZEN_COLLECTIVITEIT', collectiviteitnr);
        },
        setBvNaamVoorOverzicht: ({ commit }, naam) => {
            commit('SET_BV_NAAM_OVERZICHT', naam);
        },
        removeAVTV: ({ commit, rootGetters }) => {
            const pakketkeuzes = { aanvullend: '', tand: '' };
            commit('SET_PAKKETKEUZES', { index: rootGetters.getUserIndex, pakketkeuzes });
        },

        //medische acceptatie vragen
        setMedischeVragen: ({ commit, rootGetters }, medischeVragen) => {
            commit('SET_MEDISCHE_VRAGEN', {
                index: rootGetters.getUserIndex,
                vragen: medischeVragen
            });
        },
        setRejectReason: ({ commit, rootGetters }, rejectReason) => {
            commit('SET_REJECT_REASON', {
                index: rootGetters.getUserIndex,
                reason: rejectReason
            });
        },

        // verzekerde actions
        setVerzekerde: ({ commit, rootGetters }, verzekerde) => {
            const index = rootGetters.getUserIndex;
            commit('SET_VERZEKERDE', { index, verzekerde });
        },
        setVerzekerden: ({ commit }, value) => {
            Object.entries(value).forEach(([index, verzekerde]) =>
                commit('SET_VERZEKERDE', { index, verzekerde })
            );
        },
        setRol: ({ commit, rootGetters }, { index, value }) => {
            commit('SET_VERZEKERDE_PROP', {
                index: index || rootGetters.getUserIndex,
                key: 'rol',
                value
            });
        },
        setRegion: ({ commit, rootGetters }, { index, value }) => {
            commit('SET_VERZEKERDE_PROP', {
                index: index || rootGetters.getUserIndex,
                key: 'regio',
                value
            });
        },
        async setGeboortedatum({ commit, state, rootGetters, dispatch }, { index, value }) {
            const userIndex = index || rootGetters.getUserIndex;
            const { rol, geboortedatum } = state.verzekerden[userIndex] || {};

            // Wanneer leeftijd verandert van minderjarig naar meerderjarig (en vice versa): pakketkeuzes legen
            // Anders ontstaat er een onterechte voorselectie
            if (isLeeftijdAangepast(geboortedatum, value)) {
                commit('CLEAR_PAKKETKEUZE', { index });
            }

            commit('SET_VERZEKERDE_PROP', {
                index: userIndex,
                key: 'geboortedatum',
                value
            });

            // Reset aanvraag reden en datum wanneer geboortedatum van verzekeringnemer verandert
            // Deze gegevens zijn afhankelijk hiervan
            if (rol === ROLE_VERZEKERINGNEMER) {
                commit('SET_AANVRAAG_PROP', { key: 'reden', value: '' });
                commit('SET_AANVRAAG_PROP', { key: 'datum', value: '' });
            }

            dispatch('pakketten/clear', null, { root: true });
            await dispatch('pakketten/ophalen', null, { root: true });
            dispatch('toepassenMedischeAcceptatie');
        },

        // adres actions
        setStraatnaam: ({ commit }, value) => {
            commit('SET_ADRES_PROP', { key: 'straatnaam', value });
        },
        setHuisnummer: ({ commit }, value) => {
            isHouseNumberValid(value.toString())
                ? commit('SET_ADRES_PROP', { key: 'huisnummer', value })
                : commit('SET_ADRES_PROP', { key: 'huisnummer', value: '' });
        },
        setToevoeging: ({ commit }, value) => {
            commit('SET_ADRES_PROP', { key: 'toevoeging', value });
        },
        setPostcode: ({ commit, getters }, value) => {
            getters.getValidatiePostcode(value)
                ? commit('SET_ADRES_PROP', { key: 'postcode', value })
                : commit('SET_ADRES_PROP', { key: 'postcode', value: '' });
        },
        setWoonplaats: ({ commit }, value) => {
            commit('SET_ADRES_PROP', { key: 'woonplaats', value });
        },
        setLand: ({ commit }, value) => {
            commit('SET_ADRES_PROP', { key: 'land', value });
        },

        // betaalgegevens
        async getBetaalGegevens({ commit, state, dispatch }) {
            const appContext = app.config.globalProperties.$jss.appContext();
            const { data: betaalGegevens } = await ApiClient(appContext).get(
                `/clientcontroller/salesfunnelclient/getbetaalgegevens`
            );

            if (betaalGegevens?.BetaalWijze) {
                dispatch('setIban', betaalGegevens.Iban);
                dispatch('setBic', betaalGegevens.Bic);
                dispatch('setBetaalwijze', betaalGegevens.BetaalWijze);
                dispatch('setAfschrijfdatum', betaalGegevens.Incassodag);
            }
        },

        // adreszoeker
        async zoekenAdres({ commit, state, dispatch }, { postcode, huisnummer }) {
            try {
                commit('SET_ADRESZOEKER_PROP', { key: 'error', value: false });
                commit('SET_ADRESZOEKER_PROP', { key: 'pending', value: true });
                commit('SET_ADRESZOEKER_PROP', { key: 'success', value: false });

                const cacheKey = `${postcode}-${huisnummer}`;
                const cachedResult = state.adreszoeker.cache?.[cacheKey];
                let adres = null;

                if (cachedResult) {
                    adres = cachedResult;
                } else {
                    const appContext = app.config.globalProperties.$jss.appContext();
                    const { data } = await ApiClient(appContext).post(
                        `/clientcontroller/salesfunnelclient/zoekenadres`,
                        {
                            Postcode: postcode,
                            Huisnummer: huisnummer
                        }
                    );

                    if (data?.IsSucces && data?.AdresGevonden && data?.Adres) {
                        commit('SET_ADRESZOEKER_CACHE', { key: cacheKey, value: data.Adres });
                        adres = data.Adres;
                    }
                }

                if (adres) {
                    commit('SET_ADRESZOEKER_PROP', { key: 'success', value: true });
                    dispatch('setStraatnaam', adres.Straat);
                    dispatch('setPostcode', adres.Postcode);
                    dispatch('setHuisnummer', adres.Huisnummer);
                    dispatch('setWoonplaats', adres.Plaats);
                } else {
                    commit('SET_ADRESZOEKER_PROP', { key: 'error', value: true });
                }
            } catch (error) {
                commit('SET_ADRESZOEKER_PROP', { key: 'error', value: true });
            } finally {
                commit('SET_ADRESZOEKER_PROP', { key: 'pending', value: false });
            }
        },
        clearAdreszoeker({ commit }) {
            commit('CLEAR_ADRESZOEKER');
        },

        // contact actions
        setEmail: ({ commit }, value) => {
            commit('SET_CONTACT_PROP', { key: 'email', value: isEmailValid(value) ? value : '' });
        },
        setMobiel: ({ commit }, value) => {
            commit('SET_CONTACT_PROP', { key: 'mobiel', value });
        },

        // aanvraag actions
        setAanvraagReden: ({ commit }, value) => {
            commit('SET_AANVRAAG_PROP', { key: 'reden', value });
        },
        setAanvraagDatum: ({ commit }, value) => {
            commit('SET_AANVRAAG_PROP', { key: 'datum', value });
        },
        setDatumNieuwDienstverband: ({ commit }, value) => {
            commit('SET_AANVRAAG_PROP', { key: 'datumNieuwDienstverband', value });
        },
        setAanvraagOpzeggenVorigeVerzekering: ({ commit }, value) => {
            commit('SET_AANVRAAG_PROP', { key: 'opzeggenVorigeVerzekering', value });
        },
        setAanvraagVerzekeringOpgezegd: ({ commit }, value) => {
            commit('SET_AANVRAAG_PROP', { key: 'verzekeringOpgezegd', value });
        },

        // Offerte
        setOfferteEmail: ({ commit }, value) => {
            commit('SET_OFFERTE_PROP', { key: 'email', value });
        },
        setOfferteTerugkeerUrl: ({ commit }, value) => {
            commit('SET_OFFERTE_PROP', { key: 'terugkeerUrl', value });
        },
        clearOfferte: ({ commit }) => {
            commit('CLEAR_OFFERTE');
        },

        // bank actions
        setIban: ({ commit }, value) => {
            commit('SET_BANK_PROP', { key: 'iban', value });
        },
        setBic: ({ commit }, value) => {
            commit('SET_BANK_PROP', { key: 'bic', value });
        },
        setGespreidEr: ({ commit }, value) => {
            commit('SET_BANK_PROP', { key: 'gespreidEr', value });
        },
        setBetaalwijze: ({ commit, dispatch }, value) => {
            commit('SET_BANK_PROP', { key: 'betaalwijze', value });
            dispatch('pakketten/ophalen', null, { root: true });
        },
        async setBetaaltermijn({ commit, dispatch }, value) {
            commit('SET_BANK_PROP', { key: 'betaaltermijn', value });
            await dispatch('pakketten/ophalen', null, { root: true });
            // Na ophalen pakketten eventuele niet beschikbare TV pakketten disablen.
            dispatch('toepassenMedischeAcceptatie');
        },
        setAfschrijfdatum: ({ commit }, value) => {
            commit('SET_BANK_PROP', { key: 'afschrijfdatum', value });
        },
        setHeeftNuAi: ({ commit }, value) => {
            commit('SET_BANK_PROP', { key: 'heeftNuAI', value });
        },

        // akkoord actions
        setAkkoordBorderel: ({ commit }, value) => {
            commit('SET_AKKOORD_PROP', { key: 'borderel', value });
        },
        setAkkoordZiekenhuizen: ({ commit }, value) => {
            commit('SET_AKKOORD_PROP', { key: 'ziekenhuizen', value });
        },
        setAkkoordDocumenten: ({ commit }, value) => {
            commit('SET_AKKOORD_PROP', { key: 'documenten', value });
        },
        setAkkoordContact: ({ commit }, value) => {
            commit('SET_AKKOORD_PROP', { key: 'contact', value });
        },
        setAkkoordAlgemeneVoorwaarden: ({ commit }, value) => {
            commit('SET_AKKOORD_PROP', { key: 'algemeneVoorwaarden', value });
        },

        // prospect lijsten
        setProspectLijsten: ({ commit }, data) => {
            const { landen, nationaliteiten } = data;
            commit('SET_LANDEN', { landen });
            commit('SET_NATIONALITEITEN', { nationaliteiten });
        },
        setIncassodatums: ({ commit }, incassodatums) => {
            commit('SET_INCASSODATUMS', { incassodatums });
        },
        setDigitalePolisInfo: ({ commit, getters }, digitalePolisInfo) => {
            if (!getters.getEmail) {
                commit('SET_CONTACT_PROP', { key: 'email', value: digitalePolisInfo?.emailadres });
            }
        },
        openFamilyMemberModal({ commit }) {
            commit('SHOW_FAMILY_MEMBER_MODAL', true);
        },
        closeFamilyMemberModal({ commit }) {
            commit('SHOW_FAMILY_MEMBER_MODAL', false);
        },
        async fetchInsuranceStartDate({ commit, getters, rootGetters }) {
            if (getters.getInsuranceStartDate != null || getters.getInsuranceStartDatePending)
                return;

            try {
                commit('SET_INSURANCE_STARTDATE_PENDING', true);
                commit('SET_INSURANCE_STARTDATE_ERROR', false);
                const appContext = app.config.globalProperties.$jss.appContext();

                const { data } = await ApiClient(appContext).post(
                    `/clientcontroller/salesfunnelclient/GetInsuranceStartDate`,
                    {
                        Index: rootGetters.getUserIndex,
                        Basis: getters.getPakketKeuze('basis'),
                        EigenRisicoCode: getters.getGekozenEigenRisicoCategorie,
                        Combi: getters.getPakketKeuze('combi'),
                        Aanvullend: getters.getPakketKeuze('aanvullend'),
                        Tand: getters.getPakketKeuze('tand'),
                        Dav: getters.getPakketKeuze('dav')
                    }
                );
                if (data && 'insuranceStartDate' in data) {
                    Object.entries(data.insuranceStartDate).forEach(([key, value]) => {
                        const date = new Date(value);
                        if (isDate(date)) commit('SET_INSURANCE_STARTDATE', { key, value: date });
                    });
                } else {
                    throw new Error('Invalid data');
                }
            } catch (error) {
                commit('SET_INSURANCE_STARTDATE_ERROR', true);
            } finally {
                commit('SET_INSURANCE_STARTDATE_PENDING', false);
            }
        }
    };

    const mutations = {
        SET_PENDING(state, value) {
            state.pending = value;
        },
        SET_DELAYED(state, value) {
            state.delayed = value;
        },
        SET_ERROR(state, value) {
            state.error = value;
        },
        SET_SUCCESS(state, value) {
            state.success = value;
        },
        SET_PAKKETKEUZES: (state, { index, pakketkeuzes }) => {
            if (!state.pakketkeuzes.hasOwnProperty(index)) {
                state.pakketkeuzes[index] = { ...pakketkeuzes };
            } else {
                state.pakketkeuzes[index] = {
                    ...state.pakketkeuzes[index],
                    ...pakketkeuzes
                };
            }
            // clear insurance start date when pakketkeuzes are updated
            state.insuranceStartDate = createSubState();
        },
        SET_REGIONS: (state, regions) => {
            // by default available regions is provided as object, so cast to array before saving in the state
            if (typeof regions === 'object') {
                state.regions = Object.entries(regions).map(entry => {
                    return {
                        value: entry[0],
                        label: entry[1]
                    };
                });
                return;
            }

            state.regions = null;
        },
        SET_JUNIOR_PAKKETEN_ERROR_STATUS: (state, status) => {
            state.juniorPakkettenErrorStatus = status;
        },
        SET_TOTAAL_PAKKETKEUZES: (state, pakketkeuzes) => {
            state.pakketkeuzes = pakketkeuzes;
        },
        SET_GEKOZEN_COLLECTIVITEIT: (state, collectiviteitnr) => {
            state.collectiviteitnummer = collectiviteitnr;
            state.medischevragen = {};
            state.bank.betaaltermijn = null;
        },
        SET_BV_NAAM_OVERZICHT: (state, naam) => {
            state.bvNaamVoorOverzicht = naam;
        },
        SET_MEDISCHE_VRAGEN: (state, { index, vragen }) => {
            if (!state.medischevragen.hasOwnProperty(index)) {
                state.medischevragen[index] = vragen;
            } else {
                state.medischevragen[index] = vragen;
            }
        },
        SET_REJECT_REASON: (state, { index, reason }) => {
            if (!state.medischevragen.hasOwnProperty(index)) {
                state.medischevragen[index].reason[index] = reason;
            } else {
                state.medischevragen[index]['reason'] = reason;
            }
        },
        SET_VERZEKERDE: (state, { index, verzekerde }) => {
            if (!state.verzekerden.hasOwnProperty(index)) {
                state.verzekerden[index] = { ...verzekerde };
            } else {
                state.verzekerden[index] = {
                    ...state.verzekerden[index],
                    ...verzekerde
                };
            }
        },
        SET_VERZEKERDE_PROP: (state, { index, key, value }) => {
            if (!state.verzekerden.hasOwnProperty(index)) {
                state.verzekerden[index] = { [key]: value };
            } else {
                state.verzekerden[index][key] = value;
            }
        },
        SET_ADRES_PROP: (state, { key, value }) => {
            state.adres[key] = value;
        },
        SET_CONTACT_PROP: (state, { key, value }) => {
            state.contact[key] = value;
        },
        SET_AANVRAAG_PROP: (state, { key, value }) => {
            state.aanvraag[key] = value;
        },
        SET_BANK_PROP: (state, { key, value }) => {
            state.bank[key] = value;
        },
        SET_AKKOORD_PROP: (state, { key, value }) => {
            state.akkoord[key] = value;
        },
        SET_INCASSODATUMS: (state, { incassodatums }) => {
            state.incassodatums = incassodatums;
        },
        SET_DIGITALEPOLISINFO: (state, { digitalePolisInfo }) => {
            state.digitalePolisInfo = digitalePolisInfo;
        },
        SET_LANDEN: (state, { landen }) => {
            state.landen = landen;
        },
        SET_TANDVERZEKERINGEN: (state, vergelijkbaretandverzekeringen) => {
            state.vergelijkbaretandverzekeringen = vergelijkbaretandverzekeringen;
        },
        SET_NATIONALITEITEN: (state, { nationaliteiten }) => {
            state.nationaliteiten = nationaliteiten;
        },
        SET_OFFERTE_PROP: (state, { key, value }) => {
            state.offerte[key] = value;
        },
        SET_ADRESZOEKER_PROP: (state, { key, value }) => {
            state.adreszoeker[key] = value;
        },
        SET_ADRESZOEKER_CACHE: (state, { key, value }) => {
            if (state.adreszoeker.cache) {
                state.adreszoeker.cache[key] = value;
            } else {
                state.adreszoeker.cache = { [key]: value };
            }
        },
        CLEAR_VERZEKERDE: (state, { index }) => {
            delete state.verzekerden[index];
        },
        CLEAR_VERZEKERDEN: state => {
            state.verzekerden = {};
        },
        CLEAR_PAKKETKEUZE: (state, { index }) => {
            delete state.pakketkeuzes[index];
        },
        CLEAR_PAKKETKEUZES: state => {
            state.pakketkeuzes = {};
        },
        CLEAR_OFFERTE(state) {
            state.offerte = {};
        },
        CLEAR_ADRESZOEKER(state) {
            delete state.adreszoeker.success;
            delete state.adreszoeker.pending;
            delete state.adreszoeker.error;
        },
        CLEAR(state) {
            state.verzekerden = {};
            state.pakketkeuzes = {};
            state.adres = {};
            state.adreszoeker = {};
            state.contact = {};
            state.aanvraag = {};
            state.bank = {};
            state.akkoord = {};
            state.medischevragen = {};
            state.offerte = {};
            state.collectiviteitnummer = null;
            state.bvNaamVoorOverzicht = '';
            state.insuranceStartDate = createSubState();
        },
        PARTIAL_CLEAR(state) {
            // clear alles behalve voorletters, achternaam, nationaliteit en rol van de verzekeringnemer
            const verzekeringnemerEntry = Object.entries(state.verzekerden).find(
                VerzekerdeEntry => VerzekerdeEntry[1].rol === ROLE_VERZEKERINGNEMER
            );

            state.verzekerden = {};
            if (verzekeringnemerEntry) {
                state.verzekerden[verzekeringnemerEntry[0]] = (({
                    achternaam,
                    voorletters,
                    nationaliteitsCode,
                    rol
                }) => ({
                    achternaam,
                    voorletters,
                    nationaliteitsCode,
                    rol
                }))(verzekeringnemerEntry[1]);
            }

            // clear alles behalve een aantal waarden van de aanvraag
            if (state.aanvraag) {
                state.aanvraag = (({
                    polisbladOntvangstdatum,
                    eersteIncassodatum,
                    datumZorgpas,
                    toonVersneldeIncasso,
                    gezinsPremie,
                    opzeggenVorigeVerzekering,
                    datum,
                    datumNieuwDienstverband,
                    transactieId,
                    gegevens,
                    reden,
                    eersteIncassoMeerdereTermijnen
                }) => ({
                    polisbladOntvangstdatum,
                    eersteIncassodatum,
                    datumZorgpas,
                    toonVersneldeIncasso,
                    gezinsPremie,
                    opzeggenVorigeVerzekering,
                    datum,
                    datumNieuwDienstverband,
                    transactieId,
                    gegevens,
                    reden,
                    eersteIncassoMeerdereTermijnen
                }))(state.aanvraag);
            }

            // clear alles behalve een aantal waarden van de aanvraag
            if (state.bank) {
                state.bank = (({ betaaltermijn, afschrijfdatum, betaalwijze }) => ({
                    betaaltermijn,
                    afschrijfdatum,
                    betaalwijze
                }))(state.bank);
            }

            // clear alles van de volgende store items
            state.pakketkeuzes = {};
            if (state.adres) {
                state.adres = (({ land }) => ({
                    land
                }))(state.adres);
            }

            // clear alles behalve een aantal waarden van de aanvraag
            if (state.contact) {
                state.contact = (({ email }) => ({
                    email
                }))(state.contact);
            }

            state.akkoord = {};
        },
        CLEAR_RECHTHEBBENDEN(state) {
            const rechthebbende = Object.values(state.verzekerden).some(verzekerde =>
                verzekerde.hasOwnProperty('isRechthebbendeOpCollectiviteit')
            );

            if (!rechthebbende) return;

            return Object.keys(state.verzekerden).forEach(key => {
                state.verzekerden[key].isRechthebbendeOpCollectiviteit = null;
            });
        },
        CLEAR_REGISTRATIE_NUMMERS(state) {
            const registratienummer = Object.values(state.verzekerden).some(verzekerde =>
                verzekerde.hasOwnProperty('registratienummer')
            );

            if (!registratienummer) return;

            return Object.keys(state.verzekerden).forEach(key => {
                state.verzekerden[key].registratienummer = null;
            });
        },
        CLEAR_AANVRAAG(state) {
            state.aanvraag = {};
        },
        SHOW_FAMILY_MEMBER_MODAL(state, visibilityFlag) {
            state.showFamilyMemberModal = visibilityFlag;
        },
        SET_INSURANCE_STARTDATE: (state, { key, value }) => {
            if (!state.insuranceStartDate.data) {
                state.insuranceStartDate.data = {};
            }
            state.insuranceStartDate.data[key] = value;
        },
        SET_INSURANCE_STARTDATE_PENDING: (state, pending) => {
            state.insuranceStartDate.pending = pending;
        },
        SET_INSURANCE_STARTDATE_ERROR: (state, error) => {
            state.insuranceStartDate.error = error;
        }
    };
    return {
        namespaced: true,
        state,
        getters,
        actions,
        mutations
    };
};

export const usersStoragePaths = [
    'users.verzekerden',
    'users.pakketkeuzes',
    'users.adres',
    'users.adreszoeker',
    'users.contact',
    'users.aanvraag',
    'users.bank',
    'users.akkoord',
    'users.medischevragen',
    'users.incassodatums',
    'users.landen',
    'users.nationaliteiten',
    'users.vergelijkbaretandverzekeringen',
    'users.offerte',
    'users.juniorPakkettenErrorStatus',
    'users.collectiviteitnummer',
    'users.regions',
    'users.showFamilyMemberModal',
    'users.insuranceStartDate.data'
];

export default app => createStore(app);
