import { computed, onBeforeMount, inject } from 'vue';
import { useStore } from 'vuex';

/**
 * @module useInitializeApp
 * @description A Vue composable function responsible for initializing the application
 * by synchronizing the provided `propositie` and `appType` from the app context
 * to the Vuex store. It also clears the store if the `propositie` or `appType`
 * values have changed.
 *
 * @example
 * <script setup>
 * import { useInitializeApp } from '@/composables/use-initialize-app';
 * useInitializeApp();
 * </script>
 *
 * @returns {void}
 */
export const useInitializeApp = () => {
    const { appContext } = inject('jss');
    const store = useStore();

    const storePropositie = computed(() => store.getters['renderInfo/getPropositieFromState']);
    const storeAppType = computed(() => store.getters['renderInfo/getAppTypeFromState']);
    const propositie = computed(() => appContext().propositie);
    const appType = computed(() => appContext().appType);

    const clearStore = () => store.dispatch('clearStore');
    const setStorePropositie = propositie => store.dispatch('renderInfo/setPropositie', propositie);
    const setStoreAppType = appType => store.dispatch('renderInfo/setAppType', appType);

    onBeforeMount(() => {
        const { value: storePropositieValue } = storePropositie;
        const { value: storeAppTypeValue } = storeAppType;
        const { value: propositieValue } = propositie;
        const { value: appTypeValue } = appType;

        // Clear the store if propositie or appType has changed.
        if (storePropositieValue !== propositieValue || storeAppTypeValue !== appTypeValue) {
            clearStore();
        }

        // Set the propositie and appType from the context to the store.
        setStorePropositie(propositieValue);
        setStoreAppType(appTypeValue);
    });
};
