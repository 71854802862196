import { createMemoryHistory, createRouter as createVueRouter, createWebHistory } from 'vue-router';
import RouteHandler from '@/RouteHandler';
import { globalBeforeGuards } from './routeNavigationGuards';

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
export const routePatterns = [
    '/:lang([a-z]{2}-[A-Z]{2})/:sitecoreRoute(.*)*',
    '/:lang([a-z]{2})/:sitecoreRoute(.*)*',
    '/:sitecoreRoute(.*)*'
];

export function createRouter(app, context, isSSR) {
    const base =
        !context.siteVirtualFolder || context.siteVirtualFolder === '/'
            ? context.routerBase
            : context.siteVirtualFolder.replace(/\/$/, '') + context.routerBase;

    // create an instance of vue-router and configure routes to use the RouteHandler component
    const router = createVueRouter({
        history: isSSR ? createMemoryHistory(base) : createWebHistory(base),
        routes: routePatterns.map(routePattern => {
            return {
                // Naam toekennen aan elk route
                // Deze is 'undefined' bij de initiele page load
                // Hiermee kunnen onderscheid maak tussen client side navigation en eerste page load
                name: 'jss-route',
                path: routePattern,
                component: RouteHandler,
                props: route => ({
                    route,
                    context
                })
            };
        })
    });

    router.beforeEach(globalBeforeGuards(app));

    return router;
}
