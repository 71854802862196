import axios from 'axios';
import config from '@/temp/config';
import { isServer } from '@/utils/is-server';
// import AxiosStorage from 'axios-storage';

// set AxiosStore configuration global
// AxiosStorage.config({
//     storagePrefix: 'zorg-',
//     storageMode: 'sessionStorage',
//     maxAge: 15 * 60 * 1000
// });

/**
 * Localhost inloggen:
 * - login op lokale SC via embeddedSts: bijv: https://zka.zdn.local/consumenten/mijn/verzekering/ziezo/wijzigen
 * - pas de het .AspNet.Cookies cookie de SameSite aan None (via browser dev tools, application, cookies) - TODO dit is tijdelijke workaround
 * (Voor mogelijke oplossing zie https://alessandrofaniuolo.com/2020/04/01/how-to-adjust-the-samesite-attribute-for-the-authentication-cookie-in-a-sitecore-commerce-solution-with-a-federated-payment-provider/)
 * - gebruik localhost:3000, de clientcontroller calls gebruiken nu het .AspNet.Cookies cookie om in te loggen op SC
 */

class AxiosClient {
    constructor(appContext) {
        this._appContext = appContext;

        const headers = {
            'Content-Type': 'application/json'
        };

        /**
         * request verification token header:
         * - server (cd): get from sc layout input field
         * - experience editor (cm): empty for now, no document available on SSR
         * - localhost (jss start): empty to skip preflight request (server check disabled on local sc)
         */
        if (!isServer() && !this.isLocalhost()) {
            headers['__RequestVerificationToken'] = (
                document.querySelector("input[name='__RequestVerificationToken']") || {}
            ).value;
        }

        /**
         * baseUrl:
         * - server (cd): relative so empty (result: https://zka.zdn.local/clientcontroller/...)
         * - experience editor (cm): relative so empty (result: https://dev.zdn.local/clientcontroller/...)
         * - localhost (jss start): absolute from config (result: https://zka.zdn.local/clientcontroller/...)
         */
        const service = axios.create({
            baseURL: this.isLocalhost() ? appContext?.serviceHost : '',
            headers: headers,
            withCredentials: true,
            timeout: 30 * 1000
            // adapter: AxiosStorage.adapter
        });

        this.service = service;
    }

    get(path) {
        return this.service.request({
            method: 'GET',
            url: this.getUrl(path),
            // responseType: 'json',
            // cacheConfig: true,
            params: {
                sc_apikey: config.sitecoreApiKey
            },
            withCredentials: true
        });
    }

    post(path, data) {
        return this.service.request({
            method: 'POST',
            url: this.getUrl(path),
            // responseType: 'json',
            params: {
                sc_apikey: config.sitecoreApiKey
            },
            withCredentials: true,
            data
        });
    }

    /**
     * Returns the base path of the API
     * @param {string} clientcontrollerPath
     */
    getUrl = clientcontrollerPath => {
        const hasVirtualFolder =
            this._appContext &&
            this._appContext.siteVirtualFolder &&
            this._appContext.siteVirtualFolder !== '/';

        return hasVirtualFolder
            ? this._appContext.siteVirtualFolder.replace(/\/$/, '') + clientcontrollerPath
            : clientcontrollerPath;
    };

    isLocalhost() {
        return !isServer() && location.hostname === 'localhost';
    }
}

function ApiClient(appContext) {
    return new AxiosClient(appContext);
}

export { ApiClient };
