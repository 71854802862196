import { APP_TYPE_PROSPECT, APP_TYPE_STAPPENPLAN, APP_TYPE_WIJZIGEN } from '@/constants';
import { ApiClient } from '@/utils/apiClient';

const createStore = app => {
    const state = {
        container: {},
        propositie: '',
        appType: ''
    };

    const getAppContext = () => app.config.globalProperties.$jss.appContext();

    const getters = {
        getAppType: () => getAppContext()?.appType || APP_TYPE_WIJZIGEN,
        isProspectFlow: (_state, getters) => getters.getAppType === APP_TYPE_PROSPECT,
        isWijzigenFlow: (_state, getters) => getters.getAppType === APP_TYPE_WIJZIGEN,
        isStappenplan: (_state, getters) => getters.getAppType === APP_TYPE_STAPPENPLAN,
        getAantalDagenTotStartPremiejaar: state => state.container.aantalDagenTotStartPremiejaar,
        getIsCampagneperiode: state => state.container.isCampagneperiode,
        getPremiejaar: state => state.container.premiejaar,
        getDmrTonen: state => state.container.dmrTonen,
        showProvisionInfo: state => state.container.showProvisionInfo,
        // Premiejaar komt van de clientcontroller. Als er geen premiejaar is, is de clientside renderinfo nog niet geladen
        isFullyInitialized: state => state.container.premiejaar > 2000,
        getSiteName: () => getAppContext()?.siteName,
        getAppSiteName: () => getAppContext()?.appSiteName,
        getPropositie: () => getAppContext()?.propositie,
        getTaal: state => state.container.taal,
        // Deze 2 getters staan na het initialiseren van de app gelijk aan `getAppPropositie` en `getAppType`
        // Deze worden apart in de store bijgehouden om tijdens het initialiseren een vergelijking te doen
        // Bij verandering van app type OF propositie dient de store te worden geleegd (pakketten & users)
        // Zie ook `AppRoot.vue`
        getPropositieFromState: state => state.propositie,
        getAppTypeFromState: state => state.appType
    };

    const actions = {
        setRenderInfo: ({ commit }, renderInfo) => {
            commit('SET_RENDER_INFO', renderInfo);
        },
        setAppType: ({ commit }, appType) => {
            commit('SET_APP_TYPE', appType);
        },
        setPropositie: ({ commit }, propositie) => {
            commit('SET_PROPOSITIE', propositie);
        },
        setTaal: ({ commit }, value) => {
            commit('SET_TAAL', value);
        },
        async initializeRenderInfo({ getters, commit }) {
            if (!getters.isFullyInitialized) {
                try {
                    const propositie = getters.getPropositie;
                    const { data } = await ApiClient(getAppContext()).get(
                        `/clientcontroller/salesfunnelclient/ophalenrenderinfo?propositie=${propositie}`
                    );

                    commit('SET_RENDER_INFO', data);
                } catch (error) {
                    console.log('Kan geen renderinfo ophalen:', error.message);
                }
            }
        }
    };

    const mutations = {
        SET_RENDER_INFO: (state, renderInfo) => {
            state.container = renderInfo;
        },
        SET_APP_TYPE: (state, appType) => {
            state.appType = appType;
        },
        SET_PROPOSITIE: (state, propositie) => {
            state.propositie = propositie;
        },
        SET_TAAL: (state, value) => {
            state.container.taal = value;
        }
    };
    return {
        namespaced: true,
        state,
        getters,
        actions,
        mutations
    };
};

export const renderInfoStoragePaths = ['renderInfo'];

export default app => createStore(app);
