<template>
    <not-found
        v-if="notFound && !loading"
        :context="appState.sitecoreContext"
    />
    <route-loading v-else-if="loading" />
    <layout
        v-else
        :route="appState.routeData"
    />
</template>

<script>
import { isEditorActive } from '@sitecore-jss/sitecore-jss-vue';
import { layoutServiceFactory } from './lib/layout-service-factory';
import config from '@/temp/config';

import Layout from '@/Layout.vue';
import NotFound from '@/NotFound.vue';
import RouteLoading from '@/RouteLoading.vue';

// Dynamic route handler for Sitecore items.
// Because JSS app routes are defined in Sitecore, traditional static routing isn't enough -
// we need to load dynamic route data from Sitecore when the client side route changes.
// So vue-router delegates all route rendering to this handler, which attempts to get the right
// route data from Sitecore - and if none exists, renders the not found component.

export default {
    name: 'RouteHandler',
    components: {
        Layout,
        NotFound,
        RouteLoading
    },
    // inject: {
    //     languageIsChanging: {
    //         type: Boolean
    //     },
    //     changeAppLanguage: {
    //         type: Function
    //     }
    // },
    props: {
        route: {
            type: Object,
            default: () => ({})
        },
        context: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        const state = {
            notFound: true,
            defaultLanguage: config.defaultLanguage,
            loading: true
        };

        // To take advantage of Vue's reactive data for tracking app state changes, we need
        // to reference the same `state` object that the $jss store references in order for
        // mutations to be observed.
        // $jss is attached to the Vue instance via `SitecoreJssPlugin`.
        const appState = this.$jss.store.state;

        // if the app state has routeData, we don't need to load it and don't need a loading screen
        if (appState.routeData) {
            state.loading = false;
        }

        // route path from vue router - if route was resolved, it's not a 404
        if (this.route !== null) {
            state.notFound = false;
        }

        // if we have an initial SSR state, and that state doesn't have a valid route data,
        // then this is a 404 route.
        if (!appState.routeData) {
            state.notFound = true;
        }

        // if we have initial context data, and that context data has a language defined, set the default language
        // (this makes the language of content follow the Sitecore context language cookie)
        // note that a route-based language (i.e. /de-DE) will override this default; this is for home.
        if (appState.sitecoreContext && appState.sitecoreContext.language) {
            state.defaultLanguage = appState.sitecoreContext.language;
        }

        return { ...state, appState };
    },
    watch: {
        // watch for a change in the 'route' prop
        route(newRoute, oldRoute) {
            // if the route contains a hash value, assume the URL is a named anchor/bookmark link, e.g. /page#anchorId.
            // in that scenario, we don't want to fetch new route data but instead allow default browser behavior.
            if (newRoute.hash !== '' && newRoute.path === oldRoute.path) {
                return;
            }
            // if in Sitecore editor - force reload instead of route data update
            // avoids confusing Sitecore's editing JS
            if (isEditorActive()) {
                window.location.assign(newRoute.path);
                return;
            }

            this.updateRouteData();
        }
    },
    created() {
        // if no existing routeData is present (from SSR), get Layout Service fetching the route data
        if (!this.appState.routeData) {
            this.updateRouteData();
        }

        if (document && document.body) {
            // add current app type to body attribute so plugins outside this vue-app can react on changes (e.q. chat-widget)
            document.body.dataset.appType = this.context?.appType;
        }
    },
    methods: {
        /**
         * Loads route data from Sitecore Layout Service into appState.routeData
         */
        updateRouteData() {
            if (document && document.body) {
                // add current route to body attribute so plugins outside this vue-app can react on changes (e.q. chat-widget)
                document.body.dataset.route = this.route.fullPath.replace('/', '');
            }

            let sitecoreRoutePath = this.route.params.sitecoreRoute
                ? this.route.params.sitecoreRoute.join('/')
                : this.context.appStart; // app start route from context
            if (!sitecoreRoutePath.startsWith('/')) {
                sitecoreRoutePath = `/${sitecoreRoutePath}`;
            }

            // Geef nooit een language mee aan de layout service, zodat de service automatisch
            // de actuele language geeft van de site waarin de app embedded is.
            const language = undefined;

            this.loading = true;

            // sitecore base path to app (eg. /apps/salesfunnel-ziezo-premie-berekenen/)
            // concat with path to route (eg. /start)
            sitecoreRoutePath = `${this.context.appBase}${sitecoreRoutePath}`;

            // instantiate layout service
            const layoutServiceInstance = layoutServiceFactory.create(this.context);
            // get the route data for the new route
            layoutServiceInstance.fetchLayoutData(sitecoreRoutePath, language).then(routeData => {
                // Update the JSS store instance with the fetched data.
                // This will signal the RouteHandler to update/re-render, as well as any components
                // that are referencing the JSS store instance in their `data` object.
                this.$jss.store.setSitecoreData(routeData);
                // Update login info in store
                this.$store.ophalenLoginInfo(routeData?.sitecore?.context);
                // Update language in store
                this.$store.setLanguage(this.appState.sitecoreContext.language);
                this.notFound = !routeData?.sitecore?.route;
                this.loading = false;
            });
        }
    }
};
</script>
