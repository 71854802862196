export const propositionMapping = {
    BAP: 'Zilveren Kruis',
    DPR: 'ZieZo',
    AON: 'AON',
    PLC: 'De Christelijke',
    DFR: 'De Friesland'
};
const setSessionStorage = (key, value) => {
    sessionStorage.setItem(key, value);
};
const stringify = (obj) => JSON.stringify(obj) && JSON.stringify(obj);
export const saveFunnelInSessionStorage = (proposition, currentUrl, totalPremium) => {
    if (currentUrl.includes('welkom') || currentUrl.includes('aanvraag'))
        return;
    const SfSessionStorage = sessionStorage.getItem('salesfunnel');
    if (!SfSessionStorage)
        return;
    const setProposition = {
        name: proposition,
        data: SfSessionStorage,
        path: window.location.href,
        price: totalPremium.toString()
    };
    setSessionStorage(proposition, stringify(setProposition));
    //on change send data to other tabs
    localStorage.setItem(proposition, stringify(setProposition));
    localStorage.removeItem(proposition);
};
const currentFunnels = Object.keys(propositionMapping);
export const removeStoredFunnelFromSessionStorage = () => {
    const storedFunnels = Object.keys(sessionStorage);
    document.querySelector('.bttf-button')?.remove();
    return storedFunnels
        .filter(funnel => currentFunnels.includes(funnel))
        .map(name => sessionStorage.removeItem(name));
};
