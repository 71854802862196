<script setup>
import { ref } from 'vue';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-vue';
import { useInitializeApp } from '@/composables/use-initialize-app';
import { usePrefillApp } from '@/composables/use-prefill-app';
import { usePrefillOfferteApp } from '@/composables/use-prefill-offerte-app';
import { useTrackingApp } from '@/composables/use-tracking-app';
import { useOptimizeApp } from '@/composables/use-optimize-app';

import '@/assets/app.scss';

useInitializeApp();
usePrefillApp();
usePrefillOfferteApp();
useTrackingApp();
useOptimizeApp();

const languageIsChanging = ref(false);
</script>

<template>
    <main :class="{ 'with-exp-toolbar': isEditorActive() }">
        <router-view v-if="isEditorActive() || !languageIsChanging" />
    </main>
</template>

<style lang="scss" scoped>
.with-exp-toolbar {
    margin-top: 139px;
}
</style>
