import usePackageChoiceStep from '@/composables/use-package-choice-step';
import { isLeeftijdVanaf17Jaar10Maanden1Dag } from '@/utils/leeftijd';
import { mapGettersComp } from '@/utils/map-state';
import {
    removeStoredFunnelFromSessionStorage,
    saveFunnelInSessionStorage
} from '@/utils/modifySessionStorage';
import { NavigationGuardNext, Route } from 'vue-router'; //used only for type
import { APP_TYPE_PROSPECT, APP_TYPE_STAPPENPLAN, ROUTES } from './constants';

/**
 * @description Global before guards. This is the earliest moment in the vue lifecycle to catch route related information.
 * @see https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
 * @param {Route} to the destination route
 * @param {Route} from the origin route
 * @param {NavigationGuardNext<Vue>} next function to continue navigation
 * @returns {NavigationGuardNext<Vue>} next()
 */

let store;

export const globalBeforeGuards = app => (to, from, next) => {
    store = app.config.globalProperties.$store;
    const appType = store.getters['renderInfo/getAppType'];

    if (!from.name) {
        //determines first page load.
        store.dispatch('setUserIndex', parseIndex(to.query.index));
    }

    let redirectionPath = '';
    const propositie = store.getters['renderInfo/getPropositieFromState'];
    const totaalPremie = store.getters['users/getTotaalNieuwePremie'];

    switch (appType) {
        case APP_TYPE_PROSPECT:
            redirectionPath = getRedirectPathProspects(to, from);
            saveFunnelInSessionStorage(propositie, to.path, totaalPremie);
            break;
        case APP_TYPE_STAPPENPLAN:
            redirectionPath = getRedirectPathStappenplan(to, from);
            break;
        default:
            redirectionPath = getRedirectPathWijzigen(to, from);
    }

    if (redirectionPath) {
        next(redirectionPath);
    } else {
        next();
    }
};

/**
 * @description Testable function that either returns false or another destination path string for redirection.
 * @param {Route} to the destination route
 * @param {Route} from the origin route
 * @returns {false | string} false (when no redirect, continue to destination) | string (path to route)
 */
export function getRedirectPathWijzigen(to, from) {
    if (containsPath(to, ROUTES.SALESFUNNEL.CONTROLEREN)) {
        if (heeftPakketkeuzeGedaan()) {
            if (isMedischAfgewezen() && heeftPakketMetMedischeAcceptatie()) {
                resetGekozenTVPakket();
                return '/';
            }

            if (
                !heeftMedischeAcceptatieDoorlopen() &&
                heeftPakketMetMedischeAcceptatie() &&
                !isUitgezonderdeFrieseJongere()
            ) {
                return ROUTES.SALESFUNNEL.MEDISCHE_VRAGEN;
            }

            return false;
        } else {
            return '/';
        }
    }

    if (containsPath(to, ROUTES.SALESFUNNEL.MEDISCHE_VRAGEN)) {
        if (heeftPakketkeuzeGedaan()) {
            if (isMedischAfgewezen() && heeftPakketMetMedischeAcceptatie()) {
                resetGekozenTVPakket();
                return '/';
            }

            if (containsPath(from, ROUTES.SALESFUNNEL.CONTROLEREN)) {
                return '/';
            }

            if (heeftPakketMetMedischeAcceptatie() && !isUitgezonderdeFrieseJongere()) {
                return false;
            }
            return ROUTES.SALESFUNNEL.CONTROLEREN;
        }

        return '/';
    }

    return false;
}

/**
 *
 * @param {Route} to the destination route
 * @returns {false | string} false (when no redirect, continue to destination) | string (path to route)
 * @description Testable function that either returns false or another destination path string for redirection.
 */
export function getRedirectPathProspects(to, from) {
    let index;

    // pakket kiezen route
    if (containsPath(to, ROUTES.SALESFUNNEL.PAKKET_KIEZEN)) {
        if (to.query.offerteid) {
            return false;
        }

        if (!heeftVerzekerden()) {
            return '/';
        }

        index = store.getters['users/getIndexOntbrekendeVerzekerdeGegevens'];

        if (index) {
            setUserIndex(index);

            if (index === '1') return '/';
        }

        return false;
    }

    // medische vragen route
    if (containsPath(to, ROUTES.SALESFUNNEL.MEDISCHE_VRAGEN)) {
        if (!heeftVerzekerden()) {
            return '/';
        }

        if (heeftPakketkeuzeGedaan() || isMinderjarig()) {
            if (isMedischAfgewezen() && heeftPakketMetMedischeAcceptatie()) {
                resetGekozenTVPakket();
                return ROUTES.SALESFUNNEL.PAKKET_KIEZEN;
            }

            if (from.path.includes(ROUTES.SALESFUNNEL.CONTROLEREN)) {
                return ROUTES.SALESFUNNEL.PAKKET_KIEZEN;
            }

            if (zijnMedischeVragenNodig()) {
                return false;
            }
            return ROUTES.SALESFUNNEL.CONTROLEREN;
        }
        return ROUTES.SALESFUNNEL.PAKKET_KIEZEN;
    }

    // persoonsgegevens route
    if (containsPath(to, ROUTES.SALESFUNNEL.PERSOONSGEGEVENS)) {
        if (!heeftVerzekerden()) {
            return '/';
        }

        index = store.getters['users/getIndexOntbrekendePakketgegevens'];

        if (index) {
            setUserIndex(index);
            window.scrollTo({ top: 0 });

            return ROUTES.SALESFUNNEL.PAKKET_KIEZEN;
        }

        index = store.getters['users/getIndexOntbrekendeMedischeAcceptatie'];

        if (index) {
            setUserIndex(index);
            return ROUTES.SALESFUNNEL.MEDISCHE_VRAGEN;
        }

        setUserIndex(store.getters['users/getUserIndexVerzekeringnemer']);

        return false;
    }

    // controleren route
    if (containsPath(to, ROUTES.SALESFUNNEL.CONTROLEREN)) {
        if (from.path.includes(ROUTES.SALESFUNNEL.WELKOM)) {
            return '/aanvraag-gelukt';
        }

        if (!heeftVerzekerden()) {
            return '/';
        }

        index = store.getters['users/getIndexOntbrekendeMedischeAcceptatie'];

        if (index) {
            setUserIndex(index);
            return ROUTES.SALESFUNNEL.MEDISCHE_VRAGEN;
        }

        index = store.getters['users/getIndexOntbrekendeVerzekerdePersoonsgegevens'];

        if (index) {
            setUserIndex(index);
            return ROUTES.SALESFUNNEL.PERSOONSGEGEVENS;
        }

        if (store.getters['users/getHeeftOntbrekendeOverigePersoonsgegevens']) {
            return ROUTES.SALESFUNNEL.PERSOONSGEGEVENS;
        }

        setUserIndex(store.getters['users/getUserIndexVerzekeringnemer']);

        return false;
    }

    // welkom route
    if (containsPath(to, ROUTES.SALESFUNNEL.WELKOM)) {
        removeStoredFunnelFromSessionStorage();
        if (from.path.includes(ROUTES.SALESFUNNEL.CONTROLEREN)) {
            return false;
        } else {
            return '/';
        }
    }

    // aanvraag gelukt
    if (containsPath(to, ROUTES.SALESFUNNEL.AANVRAAG_GELUKT)) {
        removeStoredFunnelFromSessionStorage();
        if (!from.path.includes(ROUTES.SALESFUNNEL.WELKOM)) {
            return '/';
        }
    }

    // catch-all.
    return false;
}

/**
 * Geef redirect pad terug voor het stappenplan
 * @param {Route} to
 * @param {Route} from
 * @returns {string}
 */
export const getRedirectPathStappenplan = (to, from) => {
    if (containsPath(to, ROUTES.STAPPENPLAN.AFGELOPEN_JAAR)) {
        if (!isAfgelopenJaarAllowed()) {
            return '/';
        }
    }

    if (containsPath(to, ROUTES.STAPPENPLAN.ZORGKOSTEN)) {
        if (!isZorgkostenAllowed()) {
            return ROUTES.STAPPENPLAN.AFGELOPEN_JAAR;
        }
    }

    if (containsPath(to, ROUTES.STAPPENPLAN.AANKOMEND_JAAR)) {
        if (!isAankomendJaarAllowed()) {
            return '/';
        }
    }

    if (containsPath(to, ROUTES.STAPPENPLAN.JOUW_KEUZES)) {
        if (!isJouwKeuzesAllowed()) {
            return '/';
        }

        store.dispatch('stappenplan/fetchSuggestion');
        store.dispatch('pakketten/ophalen');
    }

    if (containsPath(to, ROUTES.STAPPENPLAN.JOUW_PAKKET)) {
        if (!isJouwPakketAllowed()) {
            return '/';
        }

        store.dispatch('stappenplan/fetchSuggestion');
        store.dispatch('pakketten/ophalen');
    }

    if (containsPath(to, ROUTES.STAPPENPLAN.MEDISCHE_VRAGEN)) {
        if (heeftPakketkeuzeGedaan()) {
            if (isMedischAfgewezen() && heeftPakketMetMedischeAcceptatie()) {
                resetGekozenTVPakket();
                return ROUTES.STAPPENPLAN.JOUW_PAKKET;
            }

            if (containsPath(from, ROUTES.STAPPENPLAN.JOUW_OVERZICHT)) {
                return ROUTES.STAPPENPLAN.JOUW_PAKKET;
            }

            if (heeftPakketMetMedischeAcceptatie()) {
                return '';
            }

            return ROUTES.STAPPENPLAN.JOUW_OVERZICHT;
        }

        return ROUTES.STAPPENPLAN.JOUW_PAKKET;
    }

    if (containsPath(to, ROUTES.STAPPENPLAN.JOUW_OVERZICHT)) {
        if (!isJouwOverzichtAllowed()) {
            return '/';
        }

        if (heeftPakketkeuzeGedaan()) {
            if (isMedischAfgewezen() && heeftPakketMetMedischeAcceptatie()) {
                resetGekozenTVPakket();
                return ROUTES.STAPPENPLAN.JOUW_PAKKET;
            }

            if (!heeftMedischeAcceptatieDoorlopen() && heeftPakketMetMedischeAcceptatie()) {
                return ROUTES.STAPPENPLAN.MEDISCHE_VRAGEN;
            }

            return '';
        } else {
            return ROUTES.STAPPENPLAN.JOUW_PAKKET;
        }
    }

    if (containsPath(to, ROUTES.STAPPENPLAN.AFSLUITEN)) {
        if (!isAfsluitenAllowed()) {
            return '/';
        }
    }

    return '';
};

/**
 * Controleer of een gegeven route een specifieke pad bevat
 * @param {Object} route
 * @param {String} pad
 * @returns {Boolean}
 */
export const containsPath = (route, path) => route.path.includes(path);

/**
 * @description check if the to-route is allowed to enter
 * @param {Route} to the destination route
 * @param {Route} from the origin route
 * @returns {Boolean}
 */
export const isValidPath = (to, from) => {
    // Salesfunnel
    if (containsPath(to, ROUTES.SALESFUNNEL.START)) return true;
    if (containsPath(to, ROUTES.SALESFUNNEL.PAKKET_KIEZEN)) return isPakketKiezenAllowed();
    if (containsPath(to, ROUTES.SALESFUNNEL.PERSOONSGEGEVENS)) return isPersoonsgegevensAllowed();
    if (containsPath(to, ROUTES.SALESFUNNEL.CONTROLEREN)) return isControlerenAllowed();
    if (containsPath(to, ROUTES.SALESFUNNEL.WELKOM)) return isWelkomAllowed(from);
    if (containsPath(to, ROUTES.SALESFUNNEL.GEGEVENS_AANVULLEN))
        return isGegevensAanvullenAllowed();

    // Stappenplan
    if (containsPath(to, ROUTES.STAPPENPLAN.AFGELOPEN_JAAR)) return isAfgelopenJaarAllowed();
    if (containsPath(to, ROUTES.STAPPENPLAN.ZORGKOSTEN)) return isZorgkostenAllowed();
    if (containsPath(to, ROUTES.STAPPENPLAN.AANKOMEND_JAAR)) return isAankomendJaarAllowed();
    if (containsPath(to, ROUTES.STAPPENPLAN.JOUW_KEUZES)) return isJouwKeuzesAllowed();
    if (containsPath(to, ROUTES.STAPPENPLAN.JOUW_PAKKET)) return isJouwPakketAllowed();
    if (containsPath(to, ROUTES.STAPPENPLAN.JOUW_OVERZICHT)) return isJouwOverzichtAllowed();
    if (containsPath(to, ROUTES.STAPPENPLAN.AFSLUITEN)) return isAfsluitenAllowed();

    return false;
};

/**
 * @description helper function for casting undefined into a number
 * @param {number|undefined} index user index
 * @returns {number} parsed number with default: 1
 */
function parseIndex(index) {
    const parsed = parseInt(index);
    return isNaN(parsed) ? 1 : parsed;
}

/**
 * @description helper function for checking if getters are set
 * @param {string[]} arr array of getter paths
 * @returns {boolean} true if getters are set
 */
function areGettersSet(arr) {
    const getterCollection = arr.map(el => store.getters[el]);
    return getterCollection.every(el => el?.length > 0 || el === true);
}

/**
 * @description Zet de user index in de store
 * @param {string} index
 */
function setUserIndex(index) {
    store.dispatch('setUserIndex', index);
}

function heeftVerzekerden() {
    return !!store?.getters['users/getVerzekerdenEntries'].length;
}

function isPakketKiezenAllowed() {
    return (
        heeftVerzekerden() &&
        store.getters['users/getIndexOntbrekendeVerzekerdeGegevens'] === undefined
    );
}

function isPersoonsgegevensAllowed() {
    const { canProceedToNextRoute } = usePackageChoiceStep();
    return canProceedToNextRoute.value;
}

function isControlerenAllowed() {
    const { changedToDFRDigitalProduct, hasValidEmail, getBetaalwijze } = mapGettersComp('users');
    const { getHuidigProduct, getDigitaleBasispakketten } = mapGettersComp('pakketten');
    let currentProductIsDigital = false;
    if (getHuidigProduct && getDigitaleBasispakketten) {
        const huidigBasispakket = getHuidigProduct.value('basis')?.verkorteOmschrijving;
        currentProductIsDigital = getDigitaleBasispakketten.value?.find(
            dbv => dbv === huidigBasispakket
        )
            ? true
            : false;
    }
    const { isProspectFlow } = mapGettersComp('renderInfo');

    if (isProspectFlow) {
        return (
            isPersoonsgegevensAllowed() &&
            store.getters['users/getIndexOntbrekendeVerzekerdePersoonsgegevens'] === undefined &&
            !store.getters['users/getHeeftOntbrekendeOverigePersoonsgegevens']
        );
    } else {
        const paymentMethodIsAI =
            getBetaalwijze && getBetaalwijze.value && getBetaalwijze.value.Code === '2';
        return !currentProductIsDigital && changedToDFRDigitalProduct.value
            ? hasValidEmail.value && paymentMethodIsAI
            : true;
    }
}

function isWelkomAllowed(from) {
    return from.path.includes(ROUTES.SALESFUNNEL.CONTROLEREN);
}

function isGegevensAanvullenAllowed() {
    const { changedToDFRDigitalProduct } = mapGettersComp('users');
    return changedToDFRDigitalProduct.value;
}

function heeftPakketkeuzeGedaan() {
    return areGettersSet(['users/heeftBasisverzekering', 'users/heeftEigenRisico']);
}

function isMinderjarig() {
    const verzekerde = store.getters['users/getVerzekerde'];
    return verzekerde && !isLeeftijdVanaf17Jaar10Maanden1Dag(verzekerde.geboortedatum);
}

function zijnMedischeVragenNodig() {
    const getTv = store.getters['pakketten/getProduct']('tand');
    const hoogsteTv = getTv[getTv.length - 1];
    const oorspronkelijkeTv = store.getters['pakketten/getInitieelProduct']('tand');
    const gekozenTv = store.getters['users/getGekozenProduct']('tand');
    const medischeVragenBeantwoord = store.getters['users/getMedischeVragen'];

    if (
        oorspronkelijkeTv?.verkorteOmschrijving === hoogsteTv?.verkorteOmschrijving ||
        oorspronkelijkeTv?.verkorteOmschrijving > gekozenTv?.verkorteOmschrijving
    ) {
        return false;
    }

    return (
        gekozenTv?.medischeAcceptatieNodig &&
        !medischeVragenBeantwoord &&
        !isUitgezonderdeFrieseJongere()
    );
}

function heeftMedischeAcceptatieDoorlopen() {
    return store.getters['users/heeftMedischeVragenBeantwoord'];
}

function isMedischAfgewezen() {
    const medischeAfwijsreden = store.getters['users/getRejectReason'];

    return heeftMedischeAcceptatieDoorlopen && medischeAfwijsreden !== null;
}

// Bij de Friesland staat in de polisvoorwaarden dat jongeren tot 60 dagen na hun verjaardag
// geen medische acceptatie hoeven te doorlopen
function isUitgezonderdeFrieseJongere() {
    const isDeFriesland = 'DFR' === store.getters['renderInfo/getPropositieFromState'];
    const isRondom18 = store.getters['pakketten/getIsRondom18'];
    return isDeFriesland && isRondom18;
}

function heeftPakketMetMedischeAcceptatie() {
    const gekozenTv = store.getters['users/getGekozenProduct']('tand');
    const oorspronkelijkeTv = store.getters['pakketten/getInitieelProduct']('tand');

    // Wanneer je al een pakket hebt waar medische acceptatie voor nodig is,
    // vervolgens een ander pakket kiest waar medische acceptatie voor nodig is,
    // is medische acceptatie niet nodig.
    if (
        (gekozenTv?.isHuidig && gekozenTv?.medischeAcceptatieNodig) ||
        (oorspronkelijkeTv?.medischeAcceptatieNodig && gekozenTv?.medischeAcceptatieNodig)
    ) {
        return false;
    }

    return gekozenTv?.medischeAcceptatieNodig;
}

function resetGekozenTVPakket() {
    // 'reset' van TV pakket wordt uitgevoerd wanneer iemand de 'controleren' of 'medische-vragen' routes bezoekt,
    // medisch is afgewezen maar toch (nog) een pakket heeft gekozen waar medische acceptatie voor nodig is.
    const oorspronkelijkeTv = store.getters['pakketten/getInitieelProduct']('tand');

    store.dispatch(
        'users/addSelectedProduct',
        { tand: oorspronkelijkeTv?.verkorteOmschrijving },
        { root: true }
    );
}

// Routing guards stappenplan
export const isToestemmingGegeven = () => {
    return store.getters['stappenplan/getToestemming'] !== null;
};

export const isAfgelopenJaarAllowed = () => {
    return isToestemmingGegeven();
};

export const isZorgkostenAllowed = () => {
    return store.getters['stappenplan/isToestemming'];
};

export const isAankomendJaarAllowed = () => {
    return isToestemmingGegeven();
};

export const isJouwKeuzesAllowed = () => {
    return isToestemmingGegeven();
};

export const isJouwPakketAllowed = () => {
    return isToestemmingGegeven();
};

export const isJouwOverzichtAllowed = () => {
    return isToestemmingGegeven();
};

export const isAfsluitenAllowed = () => {
    return isToestemmingGegeven();
};
