import AppRoot from '@/AppRoot.vue';
import SfStoreContextPlugin from '@/lib/SfStoreContextPlugin';
import EmbeddedAppPlugin from '@/lib/EmbeddedAppPlugin';
import SitecoreJssStorePlugin from '@/lib/SitecoreJssStorePlugin';
import { createRouter } from '@/router';
import createStore from '@/store';
import componentFactory from '@/temp/componentFactory';
import { SitecoreJssPlaceholderPlugin } from '@sitecore-jss/sitecore-jss-vue';
import { createApp as createVueApp, createSSRApp, h } from 'vue';
import VueDOMPurifyHTML from 'vue-dompurify-html';

export function createApp(appContext, initialState, isSSR) {
    const vueOptions = {
        render: () => h(AppRoot)
    };
    const app = isSSR ? createSSRApp(vueOptions) : createVueApp(vueOptions);
    const router = createRouter(app, appContext, isSSR);

    app.use(router);
    app.use(EmbeddedAppPlugin, { appContext });
    app.use(SitecoreJssStorePlugin);
    app.use(SitecoreJssPlaceholderPlugin, { componentFactory });
    app.use(
        VueDOMPurifyHTML,
        isSSR
            ? undefined
            : {
                  default: {
                      ADD_TAGS: ['svg', 'use'],
                      ADD_ATTRIBUTES: ['xlink', 'xlink:href', 'href']
                  },
                  hooks: {
                      afterSanitizeAttributes: node => {
                          if (
                              node.hasAttribute('xlink:href') &&
                              !node.getAttribute('xlink:href').match(/^#/)
                          ) {
                              node.remove();
                          }

                          // Set target to "_blank" for anchors which has the correct secure "rel" value (security)
                          if (
                              node.tagName === 'A' &&
                              node.getAttribute('rel') === 'noopener noreferrer'
                          ) {
                              node.setAttribute('target', '_blank');
                          }
                      }
                  }
              }
    );

    const store = createStore(app);
    app.use(store);
    app.use(SfStoreContextPlugin, { store });

    // if there is an initial state defined, push it into the store, where it can be referenced by interested components.
    if (initialState) {
        app.config.globalProperties.$jss.store.setSitecoreData(initialState);
    }

    app.config.devtools = process.env.NODE_ENV === 'development';
    app.provide('jss', app.config.globalProperties.$jss);

    return { app, router };
}
