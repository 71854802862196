import { ApiClient } from '@/utils/apiClient';
import { sortByProp } from '@/utils/array';
import { createSubState, mapReimbursements } from '@/utils/vuexHelpers';
import { isPostalCodeValid } from 'design.zorgdomein';

const createStore = app => {
    const state = {
        toestemming: null,
        allowedRole: false,
        polis: null,
        address: null,
        pending: false,
        error: false,
        zorgverbruik: createSubState(),
        healthcareFilters: createSubState(),
        healthcareFilter: '',
        healthcareCosts: createSubState(),
        healthcareActivities: createSubState(),
        gezondheidsdoelen: createSubState(),
        reimbursements: createSubState(),
        reimbursementSelection: [],
        suggestion: createSubState(),
        bvSuggestionKey: null
    };

    const getters = {
        getToestemming: ({ toestemming }) => toestemming,
        isToestemming: ({ toestemming }) => toestemming === '1',
        getAllowedRole: ({ allowedRole }) => allowedRole,
        getPolis: ({ polis }) => polis,
        getPostalCode: ({ address }) => {
            if (address?.postcode && isPostalCodeValid(address?.postcode)) {
                return address.postcode.substring(0, 4);
            }

            return null;
        },
        getPending: ({ pending }) => pending,
        getError: ({ error }) => error,
        getZorgverbruik: ({ zorgverbruik }) => zorgverbruik,
        getHealthcareFilters: ({ healthcareFilters }) => healthcareFilters,
        getHealthcareFilter: ({ healthcareFilter }) => healthcareFilter,
        getHealthcareCosts: ({ healthcareCosts }) => healthcareCosts,
        getHealthcareActivities: ({ healthcareActivities }) => healthcareActivities,
        getGezondheidsdoelen: ({ gezondheidsdoelen }) => gezondheidsdoelen,
        getReimbursements: ({ reimbursements }) => reimbursements,
        getAllReimbursements: ({ gezondheidsdoelen, reimbursements }) => {
            // Extract all reimbursements from health goals and reimbursement themes
            // Merge them all to a single list
            const allReimbursements = [
                ...(gezondheidsdoelen.data || []).reduce(
                    (acc, curr) => [...acc, ...curr.reimbursements],
                    []
                ),
                ...(reimbursements.data || []).reduce((acc, curr) => {
                    if (curr.subthemes?.length) {
                        return curr.subthemes.reduce(
                            (acc2, curr2) => [...acc2, ...curr2.reimbursements],
                            acc
                        );
                    }

                    return [...acc, ...curr.reimbursements];
                }, [])
            ];

            // Create an unique list without duplicates using native Map
            // Stack Overflow solution: https://stackoverflow.com/a/56768137
            return [...new Map([...allReimbursements].map(v => [v.refTitle, v])).values()];
        },
        getReimbursementSelection: ({ reimbursementSelection }) => reimbursementSelection,
        getReimbursementsReceipt: ({ reimbursementSelection }, { getAllReimbursements }) => {
            // Create section lists which contain reimbursements
            const receipt = reimbursementSelection.reduce((acc, { section, refTitle }) => {
                const reimbursement = getAllReimbursements.find(x => x.refTitle === refTitle);
                const arr = [...acc];

                if (acc.find(x => x.section === section)) {
                    arr[acc.findIndex(x => x.section === section)].reimbursements.push(
                        reimbursement
                    );
                } else {
                    arr.push({ section, reimbursements: [reimbursement] });
                }

                return arr;
            }, []);

            // Sort section lists and reimbursements alphabetically
            return sortByProp(receipt, 'section').map(x => ({
                ...x,
                reimbursements: sortByProp(x.reimbursements, 'refTitle')
            }));
        },
        getLastChosenReimbursements: ({ suggestion }) => suggestion?.lastChosenReimbursements,
        getSuggestion: ({ suggestion }) => suggestion,
        getAvSuggestion: ({ suggestion }) =>
            suggestion.data?.avProducts?.find(product => product.isAdvice) || null,
        getBvSuggestionKey: ({ bvSuggestionKey }) => bvSuggestionKey,
        getBvSuggestion: (state, _getters, _rootState, rootGetters) => {
            if (state.bvSuggestionKey) {
                const product = rootGetters['pakketten/getProduct']('basis')?.find(
                    product => product.verkorteOmschrijving === state.bvSuggestionKey
                );
                if (product) {
                    return {
                        naam: product.naam,
                        netPremium: product.nettoPremie,
                        coveredReimbursements: mapReimbursements(product.vergoedingen),
                        notCoveredReimbursements: [],
                        isAdvice: true,
                        afkorting: product.verkorteOmschrijving,
                        rangschikking: 1
                    };
                }
            }

            return null;
        },
        getTvSuggestion: (_state, _getters, _rootState, rootGetters) => verkorteOmschrijving => {
            if (verkorteOmschrijving) {
                const product = rootGetters['pakketten/getProduct']('tand')?.find(
                    product => product.verkorteOmschrijving === verkorteOmschrijving
                );

                if (product) {
                    return {
                        naam: product.naam,
                        netPremium: product.nettoPremie,
                        coveredReimbursements: mapReimbursements(product.vergoedingen),
                        notCoveredReimbursements: [],
                        isAdvice: true,
                        afkorting: product.verkorteOmschrijving,
                        rangschikking: 1
                    };
                }
            }

            return null;
        },
        hasSuggestion: (_state, getters, _rootState, rootGetters) =>
            !!getters.getAvSuggestion ||
            !!getters.getBvSuggestionKey ||
            !!getters.getTvSuggestion(rootGetters['tvkeuzehulp/getAdvies'])
    };

    const actions = {
        setToestemming: ({ commit }, value) => {
            commit('SET_TOESTEMMING', value);
        },
        setAllowedRole: ({ commit }, value) => {
            commit('SET_ALLOWED_ROLE', value);
        },
        setBvSuggestionKey: ({ commit }, value) => {
            commit('SET_BV_SUGGESTION_KEY', value);
        },
        clearReimbursements({ commit }) {
            commit('CLEAR_REIMBURSEMENTS');
        },
        toggleReimbursementSelection: ({ commit, getters }, payload) => {
            if (getters.getReimbursementSelection.find(x => x.refTitle === payload.refTitle)) {
                commit('REMOVE_REIMBURSEMENT_SELECTION', payload);
            } else {
                commit('ADD_REIMBURSEMENT_SELECTION', payload);
            }
        },
        fetchHuidigePolis: async ({ getters, commit }) => {
            if (getters.getPolis) return;

            try {
                commit('SET_ERROR', false);
                commit('SET_PENDING', true);

                const appContext = app.config.globalProperties.$jss.appContext();
                const { data } = await ApiClient(appContext).get(
                    `/clientcontroller/stappenplanclient/huidigepolis`
                );

                if (data?.huidigePolis) {
                    commit('SET_POLIS', data.huidigePolis);
                } else {
                    throw new Error('Invalid data');
                }

                if (data?.woonadres) {
                    commit('SET_ADDRESS', data.woonadres);
                }
            } catch (error) {
                commit('SET_ERROR', true);
            } finally {
                commit('SET_PENDING', false);
            }
        },
        fetchZorgverbruik: async ({ getters, commit }) => {
            if (!getters.isToestemming || getters.getZorgverbruik.data) return;

            commit('SET_ZORGVERBRUIK_ERROR', false);
            commit('SET_ZORGVERBRUIK_PENDING', true);

            try {
                const appContext = app.config.globalProperties.$jss.appContext();
                const { data } = await ApiClient(appContext).get(
                    `/clientcontroller/stappenplanclient/zorgverbruik/1`
                );

                if (data?.zorgverbruik) {
                    commit('SET_ZORGVERBRUIK', data.zorgverbruik);
                } else {
                    throw new Error('Invalid data');
                }
            } catch (error) {
                commit('SET_ZORGVERBRUIK_ERROR', true);
            } finally {
                commit('SET_ZORGVERBRUIK_PENDING', false);
            }
        },
        fetchHealthcareFilters: async ({ getters, commit }) => {
            if (!getters.isToestemming || getters.getHealthcareFilters.data) return;

            commit('SET_HEALTHCARE_FILTERS_ERROR', false);
            commit('SET_HEALTHCARE_FILTERS_PENDING', true);

            try {
                const appContext = app.config.globalProperties.$jss.appContext();
                const { data } = await ApiClient(appContext).get(
                    `/clientcontroller/stappenplanclient/zorgkostenfilters`
                );

                if (data?.zorgkostenFilters?.jaren) {
                    commit('SET_HEALTHCARE_FILTERS', data.zorgkostenFilters.jaren);
                } else {
                    throw new Error('Invalid data');
                }
            } catch (error) {
                commit('SET_HEALTHCARE_FILTERS_ERROR', true);
            } finally {
                commit('SET_HEALTHCARE_FILTERS_PENDING', false);
            }
        },
        setHealthcareFilter: ({ commit }, year) => {
            if (!year) return;

            commit('SET_HEALTHCARE_FILTER', year);
        },
        fetchHealthcareCosts: async ({ getters, commit }, jaar, index = 1) => {
            if (
                !getters.isToestemming ||
                getters.getHealthcareCosts.data?.[`${index}-${jaar}`]?.length
            )
                return;

            commit('SET_HEALTHCARE_COSTS_ERROR', false);
            commit('SET_HEALTHCARE_COSTS_PENDING', true);

            try {
                const appContext = app.config.globalProperties.$jss.appContext();
                const { data } = await ApiClient(appContext).post(
                    `/clientcontroller/stappenplanclient/zorgkosten`,
                    {
                        index,
                        jaar
                    }
                );

                if (data?.zorgkosten?.declaratieRegelGroepen) {
                    const { index, jaar, declaratieRegelGroepen } = data.zorgkosten;

                    commit('SET_HEALTHCARE_COSTS', {
                        key: `${index}-${jaar}`,
                        data: declaratieRegelGroepen
                    });
                } else {
                    throw new Error('Invalid data');
                }
            } catch (error) {
                commit('SET_HEALTHCARE_COSTS_ERROR', true);
            } finally {
                commit('SET_HEALTHCARE_COSTS_PENDING', false);
            }
        },
        fetchHealthcareActivities: async ({ getters, commit }, group) => {
            const year = getters.getHealthcareFilter;
            const { claimNumber, handlingNumber, productGroup } = group;
            const key = `1-${year}-${claimNumber}-${handlingNumber}-${productGroup}`;

            if (!getters.isToestemming || getters.getHealthcareActivities.data?.[key]) return;

            commit('SET_HEALTHCARE_ACTIVITIES_ERROR', false);
            commit('SET_HEALTHCARE_ACTIVITIES_PENDING', true);

            try {
                const appContext = app.config.globalProperties.$jss.appContext();
                const { data } = await ApiClient(appContext).post(
                    `/clientcontroller/stappenplanclient/zorgactiviteiten`,
                    {
                        index: 1,
                        jaar: year,
                        declaratienummer: claimNumber,
                        afhandelingsnummer: handlingNumber,
                        kenmerkControle: productGroup
                    }
                );

                if (data?.zorgactiviteiten?.zorgActiviteiten) {
                    commit('SET_HEALTHCARE_ACTIVITIES', {
                        key,
                        data: data.zorgactiviteiten.zorgActiviteiten
                    });
                } else {
                    throw new Error('Invalid data');
                }
            } catch (error) {
                commit('SET_HEALTHCARE_ACTIVITIES_ERROR', true);
            } finally {
                commit('SET_HEALTHCARE_ACTIVITIES_PENDING', false);
            }
        },
        fetchGezondheidsdoelen: async ({ getters, commit }) => {
            if (getters.getGezondheidsdoelen.data) return;

            commit('SET_GEZONDHEIDSDOELEN_ERROR', false);
            commit('SET_GEZONDHEIDSDOELEN_PENDING', true);

            try {
                const appContext = app.config.globalProperties.$jss.appContext();
                const { data } = await ApiClient(appContext).post(
                    `/clientcontroller/stappenplanclient/healthobjectives`,
                    {
                        proposition: appContext?.propositie
                    }
                );

                if (data?.healthObjectives && data?.healthObjectives.length) {
                    commit('SET_GEZONDHEIDSDOELEN', data.healthObjectives);
                } else {
                    throw new Error('Invalid data');
                }
            } catch (error) {
                commit('SET_GEZONDHEIDSDOELEN_ERROR', true);
            } finally {
                commit('SET_GEZONDHEIDSDOELEN_PENDING', false);
            }
        },
        fetchReimbursements: async ({ getters, commit }) => {
            if (getters.getReimbursements.data) return;

            commit('SET_REIMBURSEMENTS_ERROR', false);
            commit('SET_REIMBURSEMENTS_PENDING', true);

            try {
                const appContext = app.config.globalProperties.$jss.appContext();
                const { data } = await ApiClient(appContext).post(
                    `/clientcontroller/stappenplanclient/reimbursements`,
                    {
                        proposition: appContext?.propositie
                    }
                );

                if (data?.themes && data?.themes.length) {
                    commit('SET_REIMBURSEMENTS', data.themes);
                } else {
                    throw new Error('Invalid data');
                }
            } catch (error) {
                commit('SET_REIMBURSEMENTS_ERROR', true);
            } finally {
                commit('SET_REIMBURSEMENTS_PENDING', false);
            }
        },
        fetchSuggestion: async ({ getters, commit }) => {
            const reimbursements = getters.getReimbursementSelection.map(({ refTitle }) =>
                refTitle.toLowerCase()
            );

            // Prevent fetching suggestion with same reimbursement selection as before
            if (
                JSON.stringify(reimbursements) ===
                JSON.stringify(getters.getLastChosenReimbursements)
            ) {
                return;
            }

            commit('SET_SUGGESTION_ERROR', false);
            commit('SET_SUGGESTION_PENDING', true);

            try {
                const appContext = app.config.globalProperties.$jss.appContext();
                const { data } = await ApiClient(appContext).post(
                    `/clientcontroller/stappenplanclient/getstappenplanadvice`,
                    {
                        proposition: appContext?.propositie,
                        chosenReimbursements: reimbursements
                    }
                );

                if (data?.viewModel) {
                    commit('SET_SUGGESTION_DATA', data.viewModel);
                    commit('SET_SUGGESTION_REIMBURSEMENTS', reimbursements);
                } else {
                    throw new Error('Invalid data');
                }
            } catch (error) {
                commit('SET_SUGGESTION_ERROR', true);
            } finally {
                commit('SET_SUGGESTION_PENDING', false);
            }
        }
    };

    const mutations = {
        SET_ALLOWED_ROLE: (state, allowedRole) => {
            state.allowedRole = allowedRole;
        },
        SET_TOESTEMMING: (state, toestemming) => {
            state.toestemming = toestemming;
        },
        SET_POLIS: (state, polis) => {
            state.polis = polis;
        },
        SET_ADDRESS: (state, address) => {
            state.address = address;
        },
        SET_PENDING: (state, pending) => {
            state.pending = pending;
        },
        SET_ERROR: (state, error) => {
            state.error = error;
        },
        SET_ZORGVERBRUIK: (state, data) => {
            state.zorgverbruik.data = data;
        },
        SET_ZORGVERBRUIK_PENDING: (state, pending) => {
            state.zorgverbruik.pending = pending;
        },
        SET_ZORGVERBRUIK_ERROR: (state, error) => {
            state.zorgverbruik.error = error;
        },
        SET_HEALTHCARE_FILTERS: (state, data) => {
            state.healthcareFilters.data = data;
        },
        SET_HEALTHCARE_FILTERS_PENDING: (state, pending) => {
            state.healthcareFilters.pending = pending;
        },
        SET_HEALTHCARE_FILTERS_ERROR: (state, error) => {
            state.healthcareFilters.error = error;
        },
        SET_HEALTHCARE_FILTER: (state, data) => {
            state.healthcareFilter = data;
        },
        SET_HEALTHCARE_COSTS: (state, { key, data }) => {
            if (state.healthcareCosts.data) {
                state.healthcareCosts.data[key] = data;
            } else {
                state.healthcareCosts.data = {
                    [key]: data
                };
            }
        },
        SET_HEALTHCARE_COSTS_PENDING: (state, pending) => {
            state.healthcareCosts.pending = pending;
        },
        SET_HEALTHCARE_COSTS_ERROR: (state, error) => {
            state.healthcareCosts.error = error;
        },
        SET_HEALTHCARE_ACTIVITIES: (state, { key, data }) => {
            if (state.healthcareActivities.data) {
                state.healthcareActivities.data[key] = data;
            } else {
                state.healthcareActivities.data = {
                    [key]: data
                };
            }
        },
        SET_HEALTHCARE_ACTIVITIES_PENDING: (state, pending) => {
            state.healthcareActivities.pending = pending;
        },
        SET_HEALTHCARE_ACTIVITIES_ERROR: (state, error) => {
            state.healthcareActivities.error = error;
        },
        SET_GEZONDHEIDSDOELEN: (state, data) => {
            state.gezondheidsdoelen.data = data;
        },
        SET_GEZONDHEIDSDOELEN_PENDING: (state, pending) => {
            state.gezondheidsdoelen.pending = pending;
        },
        SET_GEZONDHEIDSDOELEN_ERROR: (state, error) => {
            state.gezondheidsdoelen.error = error;
        },
        SET_REIMBURSEMENTS: (state, data) => {
            state.reimbursements.data = data;
        },
        SET_REIMBURSEMENTS_PENDING: (state, pending) => {
            state.reimbursements.pending = pending;
        },
        SET_REIMBURSEMENTS_ERROR: (state, error) => {
            state.reimbursements.error = error;
        },
        CLEAR_REIMBURSEMENTS(state) {
            state.reimbursements = {
                data: null,
                error: false,
                pending: false
            };
        },
        ADD_REIMBURSEMENT_SELECTION: (state, payload) => {
            state.reimbursementSelection = [...state.reimbursementSelection, payload];
        },
        REMOVE_REIMBURSEMENT_SELECTION: (state, payload) => {
            state.reimbursementSelection = state.reimbursementSelection.filter(
                x => x.refTitle !== payload.refTitle
            );
        },
        SET_SUGGESTION_DATA: (state, data) => {
            state.suggestion.data = data;
        },
        SET_SUGGESTION_PENDING: (state, pending) => {
            state.suggestion.pending = pending;
        },
        SET_SUGGESTION_ERROR: (state, error) => {
            state.suggestion.error = error;
        },
        SET_SUGGESTION_REIMBURSEMENTS: (state, reimbursements) => {
            state.suggestion.lastChosenReimbursements = reimbursements;
        },
        SET_BV_SUGGESTION_KEY: (state, key) => {
            state.bvSuggestionKey = key;
        },
        CLEAR: state => {
            state.toestemming = null;
            state.allowedRole = false;
            state.polis = null;
            state.address = null;
            state.pending = false;
            state.error = false;
            state.zorgverbruik = createSubState();
            state.healthcareFilters = createSubState();
            state.healthcareFilter = '';
            state.healthcareCosts = createSubState();
            state.healthcareActivities = createSubState();
            state.gezondheidsdoelen = createSubState();
            state.reimbursements = createSubState();
            state.reimbursementSelection = [];
            state.suggestion = createSubState();
        }
    };

    return {
        namespaced: true,
        state,
        getters,
        actions,
        mutations
    };
};

export const stappenplanStoragePaths = [
    'stappenplan.toestemming',
    'stappenplan.allowedRole',
    'stappenplan.polis',
    'stappenplan.address',
    'stappenplan.zorgverbruik.data',
    'stappenplan.healthcareFilters.data',
    'stappenplan.healthcareCosts.data',
    'stappenplan.healthcareActivities.data',
    'stappenplan.gezondheidsdoelen.data',
    'stappenplan.reimbursements.data',
    'stappenplan.reimbursementSelection',
    'stappenplan.suggestion.data',
    'stappenplan.suggestion.lastChosenReimbursements',
    'stappenplan.bvSuggestionKey'
];

export default app => createStore(app);
