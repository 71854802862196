/**
 * Scroll an element into view
 * Alternative to Element.scrollIntoView
 * To prevent shifting whole page
 * @param {Element} el
 */
export const scrollInView = el => {
    if (!el || !el.parentNode) {
        return;
    }

    const elLeft = el.offsetLeft + el.offsetWidth;
    const elParentLeft = el.parentNode.offsetLeft + el.parentNode.offsetWidth;

    // Check if element not in view
    if (elLeft >= elParentLeft + el.parentNode.scrollLeft) {
        el.parentNode.scrollLeft = elLeft - elParentLeft;
    } else if (elLeft <= el.parentNode.offsetLeft + el.parentNode.scrollLeft) {
        el.parentNode.scrollLeft = el.offsetLeft - el.parentNode.offsetLeft;
    }
};

/**
 * Get main header element
 * @returns {HTMElement}
 */
export const getHeaderElement = () => document.getElementById('nav-main');

/**
 * Get main header height
 * Used as offset for scrolling to elements
 * @returns {Number}
 */
export const getHeaderOffset = () => getHeaderElement()?.scrollHeight || 0;

/**
 * Get requested cookie
 * @returns {String}
 */
export const getCookie = name =>
    document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || '';
